import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Storage } from '@capacitor/storage';
import API_URL from '../utils/api';
import { IonButton, IonRouterLink } from '@ionic/react';

const RegistrerenForm: React.FC = () => {
  const history = useHistory();
  const [voornaam, setVoornaam] = useState<string>('');
  const [familienaam, setFamilienaam] = useState<string>('');
  const [emailadres, setEmailadres] = useState<string>('');
  const [wachtwoord, setWachtwoord] = useState<string>('');
  const [cwachtwoord, setCWachtwoord] = useState<string>('');
  const [wantpaperLogbook, setWantpaperLogbook] = useState<boolean>(false);
  const [telefoon, setTelefoon] = useState<number>();
  const [adress, setAdress] = useState<string>('');
  const [postcode, setPostcode] = useState<number>();
  const [plaatsnaam, setPlaatsnaam] = useState<string>('');
  const [land, setLand] = useState<string>('België');
  const [privacy, setPrivacy] = useState<boolean>(false);
  const [info, setInfo] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [spinner, setSpinner] = useState<string>('c-spinner-hide');

  const gebruiker = {
    firstname: voornaam,
    lastname: familienaam,
    email: emailadres,
    password: wachtwoord,
    confirmpassword: cwachtwoord,
    wantpaperLogbook: wantpaperLogbook,
    phone: telefoon,
    address: adress,
    postalCode: postcode,
    city: plaatsnaam,
    country: land,
  };

  const handleSubmit = async () => {

    if (wachtwoord != cwachtwoord) {
      setError("Beide wachtwoorden moeten overeenkomen");
      return;
    }

    setSpinner('c-spinner');
    if(!privacy){
      setError("U moet de privacyvoorwaarden aanvaarden om te kunnen registreren!");
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(gebruiker),
    };
    await fetch(`${API_URL}/register`, requestOptions)
      .then(async (response) => {
        if(!response.ok){
          throw await response.text();
        }
        setError('');
        setInfo(await response.text());
        document.getElementById('register-form-content').style.display ='none';
        document.getElementById('register-input-success').style.display ='flex';
        setSpinner('c-spinner-hide');
        //history.push('/Login');
      })
      .catch((error) => {
        if(error.message){
          setError(error.message);
        }
        else{
          setError(error);
        }
        setSpinner('c-spinner-hide');
      });
  };

  const validatePostcode = (val: number, max: number) => {
    if(val < 0) val = 0;
    if(val > max) val = Math.floor(val/10);
    setPostcode(val);
  };

  const validateTelefoon = (val: number, max: number) => {
    if(val < 0) val = 0;
    if(val > max) val = Math.floor(val/10);
    setTelefoon(val);
  };

  const setWant = (val: boolean) => {
    setWantpaperLogbook(val);
    if(val){
      document.getElementById('paper-want').style.display ='block';
    }
    else{
      document.getElementById('paper-want').style.display ='none';
    }
  };

  return (
    <div className="container background">
      <img src="assets/images/recreatievezeevisserij-contrast.png" className="c-login-logo-registreren" alt="logo recvis" />
      <div className="c-form-div">
      <p className="c-form-error">{error}</p>
      <div id='register-input-success' className='c-form-item' style={{display: "none"}}>
          <p className="c-form-info">{info}</p>
        </div>
        <div id='register-form-content'>
        <div className="c-form-item">
          <label htmlFor="" className="c-form-label required">
            Voornaam
          </label>
          <input type="text" className="c-form-input" value={voornaam} onChange={(e) => setVoornaam(e.target.value)} />
        </div>
        <div className="c-form-item">
          <label htmlFor="" className="c-form-label required">
            Familienaam
          </label>
          <input type="text" className="c-form-input" value={familienaam} onChange={(e) => setFamilienaam(e.target.value)} />
        </div>
        <div className="c-form-item">
          <label htmlFor="" className="c-form-label required">
            E-mailadres
          </label>
          <input type="email" className="c-form-input" value={emailadres} onChange={(e) => setEmailadres(e.target.value)} />
        </div>
        <div className="c-form-item">
          <label htmlFor="" className="c-form-label required">
            Wachtwoord
          </label>
          <input type="password" className="c-form-input" value={wachtwoord} onChange={(e) => setWachtwoord(e.target.value)} />
        </div>
        <div className="c-form-item">
          <label htmlFor="" className="c-form-label required">
          Wachtwoord herhalen
          </label>
          <input type="password" className="c-form-input" value={cwachtwoord} onChange={(e) => setCWachtwoord(e.target.value)} />
        </div>
        <div className='form-cb-left'>
          <input type="checkbox" className="c-login-checkbox-input" checked={wantpaperLogbook} onChange={(e) => setWant(e.target.checked)} />
          <label htmlFor="" className="c-login-checkbox-label">
            Ik wens een papieren logboek en meetsticker te ontvangen
          </label>
        </div>
        <div id='paper-want' style={{'display': 'none'}}>
        <div className="c-form-item">
          <label htmlFor="" className="c-form-label">
            Telefoon
          </label>
          <input type="number" min={0} max={9999999999} className="c-form-input" value={telefoon} onChange={(e) => validateTelefoon(parseInt(e.target.value),9999999999)} />
        </div>
        <div className="c-form-item">
          <label htmlFor="" className="c-form-label required">
            Straat en huisnummer
          </label>
          <input type="text" className="c-form-input" value={adress} onChange={(e) => setAdress(e.target.value)} />
        </div>
        <div className="c-form-item">
          <label htmlFor="" className="c-form-label required">
            Postcode
          </label>
          <input type="number" min={0} max={9999} className="c-form-input" value={postcode} onChange={(e) => validatePostcode(parseInt(e.target.value),9999)} />
        </div>
        <div className="c-form-item">
          <label htmlFor="" className="c-form-label required">
            Plaatsnaam
          </label>
          <input type="text" className="c-form-input" value={plaatsnaam} onChange={(e) => setPlaatsnaam(e.target.value)} />
        </div>
        <div className="c-form-item">
          <label htmlFor="" className="c-form-label required">
            Land
          </label>
          <input type="text" disabled={false} className="c-form-input" value={land} onChange={(e) => setLand(e.target.value)} />
        </div>

        </div>
        <div className='form-cb-left'>
        <input type="checkbox" className="c-login-checkbox-input" id="TOS" name="TOS" checked={privacy} onChange={(e) => setPrivacy(e.target.checked)} />
          <label className="c-login-checkbox-label" htmlFor="TOS">
            Ik ga akkoord met het <b><a href='https://www.recreatievezeevisserij.be/privacybeleid/' target='_blank'>privacybeleid</a></b>
          </label>
        </div>
        
<div className='c-form-item register-btn-div'> 
       <IonButton  onClick={() => handleSubmit()}>Registreren</IonButton>
</div>
        {/* <button type="submit" className="c-button" onClick={() => handleSubmit()}>
          Registreren
        </button> */}
        </div>

        </div>

        <div className="c-form-div">
      <b>
      <p>Al een account?  <IonRouterLink routerLink="/Login" className="c-login-geen_acc primary-underline no-decoration">Log je in!</IonRouterLink> </p>

        </b>
      </div>
    </div>
  );
};

export default RegistrerenForm;
