import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Storage } from '@capacitor/storage';
import API_URL from '../utils/api';
import { IonButton, IonRouterLink, IonSpinner } from '@ionic/react';
import { url } from 'inspector';


const WWResetForm: React.FC = () => {
  const history = useHistory();
  const [wachtwoord, setWachtwoord] = useState<string>('');
  const [wachtwoord2, setWachtwoord2] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [info, setInfo] = useState<string>('');
  const [spinner, setSpinner] = useState<string>('c-spinner-hide');
  const userid = new URLSearchParams(window.location.search).get("userid")
  const pwresettoken = new URLSearchParams(window.location.search).get("pwresettoken")
  const gebruiker = {
    userId: parseInt(userid),
    pwresettoken: pwresettoken,
    password: wachtwoord,
  };
  const handleSubmit = async () => {
    if (wachtwoord != wachtwoord2) {
      setError("Beide wachtwoorden moeten overeenkomen");
      return;
    }

    setSpinner('c-spinner');
    
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(gebruiker),
      };
      await fetch(`${API_URL}/resetpassword`, requestOptions)
        .then(async (response) => {
          if(!response.ok){
            throw await response.text();
          }
          
          setInfo(await response.text());
          setSpinner('c-spinner-hide');
          document.getElementById('reset-input-success').style.display ='flex';
          document.getElementById('forgot-input-complete').style.display ='none';
          //history.push('/Login');
        })
        .catch((error) => {
          if(error.message){
            setError(error.message);
          }
          else{
            setError(error);
          }
          
          setSpinner('c-spinner-hide');
        });
  };
  return (
    <div className="container container-login background">
      <img src="assets/images/recreatievezeevisserij-contrast.png" className="c-login-logo-registreren" alt="logo recvis" />
      <div className="c-form-div">
      <p className="c-form-error">{error}</p>
      <div id='reset-input-success' className='c-form-item' style={{display: "none"}} >
          <p className="c-form-info">{info}</p>
          <div className="c-form-div">
      <b>
          <p>Terug naar <IonRouterLink routerLink="/Login" className="primary-underline no-decoration">Login</IonRouterLink></p>
        </b>
      </div>
        </div>
        <div id='forgot-input-complete' className='full-width'>
        <div className="c-form-item">
          <label htmlFor="wachtwoord" className="c-form-label">
            Wachtwoord
          </label>
          <input type="password" className="c-form-input" id="wachtwoord" onChange={(e) => setWachtwoord(e.target.value)} />
        </div>
        <div className="c-form-item">
          <label htmlFor="wachtwoordherhalen" className="c-form-label">
            Wachtwoord herhalen
          </label>
          <input type="password" className="c-form-input" id="wachtwoordherhalen" onChange={(e) => setWachtwoord2(e.target.value)} />
        </div>
        <div className='c-form-item register-btn-div'> 
          <IonButton  onClick={() => handleSubmit()}>Wachtwoord instellen</IonButton>
        </div>  
        </div>
 
        <div className={spinner}>
            <IonSpinner />
          </div>   
        </div>
    </div>
  );
};

export default WWResetForm;
