import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Storage } from '@capacitor/storage';
import API_URL from '../utils/api';
import { IonButton, IonRouterLink, IonSpinner } from '@ionic/react';
import { url } from 'inspector';


const EmailValidateForm: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState<string>('');
  const [info, setInfo] = useState<string>('');
  const [spinner, setSpinner] = useState<string>('c-spinner');
  const userid = new URLSearchParams(window.location.search).get("userid")
  const emailtoken = new URLSearchParams(window.location.search).get("emailtoken")

  useEffect(() => {
    setSpinner('c-spinner');
    
    const requestOptions = {
      method: 'GET',
    };
    var url = encodeURI(`${API_URL}/validate?userid=${userid}&token=${emailtoken}`);
    fetch(url, requestOptions)
      .then(async (response) => {
        if(!response.ok){
          throw await response.text();
        }
        
        setInfo(await response.text());
        setSpinner('c-spinner-hide');
        document.getElementById('validate-input-success').style.display ='flex';
        //history.push('/Login');
      })
      .catch((error) => {
        if(error.message){
          setError(error.message);
        }
        else{
          setError(error);
        }
        
        setSpinner('c-spinner-hide');
      });
  }, []);


  return (
    <div className="container container-login background">
      <img src="assets/images/recreatievezeevisserij-contrast.png" className="c-login-logo-registreren" alt="logo recvis" />
      <div className="c-form-div">
        <p className="c-form-error">{error}</p>
        <div id='validate-input-success' className='c-form-item' style={{display: "none"}} >
          <p className="c-form-info">{info}</p>
          
        </div>
        <div className={spinner}>
          <IonSpinner />
        </div>   
      </div>
    </div>
  );
};

export default EmailValidateForm;
