import { useHistory } from 'react-router-dom';
import { Storage } from '@capacitor/storage';
import { IonPopover, IonButton, IonIcon } from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import { addOutline, closeOutline, chevronBackOutline } from 'ionicons/icons';
import API_URL from '../utils/api';
import dataAccess from '../utils/fetch';
import { refreshSharp } from 'ionicons/icons';

interface data {
  id: string;
  name: string;
  location: string;
  measur: string;
}

const VangstToevoegenScreen: React.FC = () => {
  const [vissen, setVissen] = useState<data[]>([]);
  const [vis, setVis] = useState<string>();
  const [visFull, setVisFull] = useState<any>({});
  const [count, setCount] = useState<number>(1);
  const history = useHistory();
  let rows: any = [];
  useEffect(() => {
    (async function () {
      let vissen = await dataAccess.get('species');
      setVissen(vissen);
    })();
    vissen.forEach((element) => {
      if (element.id == vis) {
        setVisFull(element);
      }
    });
  }, [vis, count]);

  const close = (id: number) => {
    rows.splice(id, 1);
    setCount(count - 1);
    NieuweVisToevoegen();
  };

  const terug = () => {
    history.goBack();
  };
  const toevoegen = () => {
    if (visFull['measure'] == 'hoeveelheid+gewicht') {
    } else if (visFull['measure'] == 'gewicht') {
    } else if (visFull['measure'] == 'lengtes') {
    }
  };
  const NieuweVisToevoegen = () => {
    if (visFull['measure'] == 'hoeveelheid+gewicht') {
      rows.push(
        <div className="c-logboek-div-visvangst-full">
          <div className="c-logboek-div-visvangst">
            <h5 className="c-logboek-titel-h5">Houden</h5>
            <div className="c-logboek-div-visvangst-cm">
              <input className="c-form-input-duur" id="cm" name="cm" type="text" /> stuks
            </div>
            <div className="c-logboek-div-visvangst-cm">
              Totaal aantal kg: <input className="c-form-input-duur" id="kg" name="kg" type="text" />
            </div>
            <hr className="c-logboek-divider"></hr>
            <h5 className="c-logboek-titel-h5">Teruggooi</h5>
            <div className="c-logboek-div-visvangst-cm">
              Ondermaats
              <input className="c-form-input-duur" id="ondermaats" name="ondermaats" type="text" /> stuks
            </div>
            <div className="c-logboek-div-visvangst-cm">
              Bovenmaats
              <input className="c-form-input-duur" id="bovenmaats" name="bovenmaats" type="text" /> stuks
            </div>
          </div>
        </div>
      );
    } else if (visFull['measure'] == 'gewicht') {
      rows.push(
        <div className="c-logboek-div-visvangst-full">
          <div className="c-logboek-div-visvangst">
            <div className="c-logboek-div-visvangst-cm">
              Totaal aantal kg: <input className="c-form-input-duur" id="kg" name="kg" type="text" />
            </div>
          </div>
        </div>
      );
    } else if (visFull['measure'] == 'lengtes') {
      for (let i = 0; i < count; i++) {
        rows.push(
          <div className="c-logboek-div-visvangst-full">
            <button className="c-logboek-div-visvangst-full-close" onClick={() => close(i)}>
              <IonIcon md={closeOutline} className="c-vangst-icon"></IonIcon>
            </button>
            <div className="c-logboek-div-visvangst">
              <div className="c-logboek-div-visvangst-radio">
                <div className="c-logboek-div-visvangst-radio-item">
                  <input type="radio" id={'houden' + i} name={'vis' + i} value={'houden' + i} checked />
                  <label className="c-form-radio-label" htmlFor="houden">
                    Houden
                  </label>
                </div>
                <div className="c-logboek-div-visvangst-radio-item">
                  <input type="radio" id={'teruggooi' + i} name={'vis' + i} value={'teruggooi' + i} />
                  <label className="c-form-radio-label" htmlFor="teruggooi">
                    Teruggooi
                  </label>
                </div>
              </div>
              <div className="c-logboek-div-visvangst-cm">
                <input className="c-form-input-duur" id="cm" name="cm" type="text" /> cm
              </div>
            </div>
          </div>
        );
      }
      rows.push(
        <button className="c-button-full c-button-addvis" onClick={() => setCount(count + 1)}>
          <IonIcon md={addOutline} className="c-vangst-icon"></IonIcon>
        </button>
      );
    }

    return rows;
  };
  const listVisGen = () => {
    return (
      <>
        {vissen.map((message) => (
          <option className="c-form-select-option" value={message.id}>
            {message.name}
          </option>
        ))}
      </>
    );
  };
  return (
    <div className="container container-home container-loggedIn">
      <div className="c-vangst-div">
        <h1 className="c-logboek-titel">Vangst</h1>
        <button className="c-button-terug" onClick={() => terug()}>
          <IonIcon md={chevronBackOutline} className="c-terug-icon"></IonIcon>
        </button>
        <div className="c-form-item c-form-item-logboek">
          <select className="c-form-select" name="vissen" id="vissen" onChange={(e) => setVis(e.target.value)} value={vis}>
            {listVisGen()}
          </select>
          {NieuweVisToevoegen()}

          <button className="c-button-full c-button-toevoegen" onClick={() => toevoegen()}>
            Toevoegen
          </button>
        </div>
      </div>
    </div>
  );
};

export default VangstToevoegenScreen;
