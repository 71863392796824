import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Storage } from '@capacitor/storage';
import { IonSpinner, IonContent, IonButton, IonItem, IonLabel, IonRouterLink } from '@ionic/react';
import API_URL from '../utils/api';

const LoginForm: React.FC = () => {
  const history = useHistory();
  const [gebruikersnaam, setGebruikersnaam] = useState<string>('');
  const [wachtwoord, setWachtwoord] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [spinner, setSpinner] = useState<string>('c-spinner-hide');
  const gebruiker = {
    username: gebruikersnaam,
    password: wachtwoord,
  };

  useEffect(() => {
    (async function () {
        const code = await Storage.get({ key: `code` });
        const tokenValidTo = await Storage.get({ key: `token_valid_to` });
        if (code.value && tokenValidTo.value) {
          // @ts-ignore
          if(parseInt(tokenValidTo.value) > new Date().getTime()){
            history.push('/Home');
          }
      }
      // hackjob
      document.getElementById('main').classList.remove("menu-content-open");
    })();
  }, []);

  const validateForm = () => {
    return gebruikersnaam.length > 0 && wachtwoord.length > 0;
  };

  const handleSubmit = async () => {
    setSpinner('c-spinner');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(gebruiker),
    };
    await fetch(`${API_URL}/login`, requestOptions)
      .then(async (response) => {
        if(!response.ok){
          throw await response.text();
        }
        const data = await response.json();

        await Storage.set({
          key: 'token',
          value: data.token,
        });
        await Storage.set({
          key: 'token_valid_to',
          value: data.endTime.toString(),
        });
        await Storage.set({
          key: 'admin',
          value: data.isAdmin.toString(),
        });
        await Storage.set({
          key: 'code',
          value: data.code,
        });
        setSpinner('c-spinner-hide');
        history.push('/Home');
      })
      .catch((error) => {
        setSpinner('c-spinner-hide');
        if(error.message){
          setError(error.message);
        }
        else{
          setError(error);
        }
      });
  };

  return (
    <div className="container container-login background">
      <img src="assets/images/recreatievezeevisserij-contrast.png" className="c-login-logo-registreren" alt="logo recreatievezeevisserij" />
      <div className="c-form-div">
        <p className="c-form-error">{error}</p>
        <div className="c-form-item">
          <label htmlFor="gebruikersnaam" className="c-form-label">
            E-mail of identificatiecode
          </label>
          <input type="text" className="c-form-input" id="gebruikersnaam" value={gebruikersnaam} onChange={(e) => setGebruikersnaam(e.target.value)} />
        </div>
        <div className="c-form-item">
          <label htmlFor="wachtwoord" className="c-form-label">
            Wachtwoord
          </label>
          <input type="password" className="c-form-input" id="wachtwoord" value={wachtwoord} onChange={(e) => setWachtwoord(e.target.value)} />

        </div>
        <div className="c-form-item login-btn-div">
        {/* <button type="submit" className="c-button" value="Inloggen" disabled={!validateForm()} onClick={() => handleSubmit()}>
          Inloggen
        </button> */}
        <IonButton  disabled={!validateForm()} onClick={() => handleSubmit()}>Log in</IonButton>
        <div className={spinner}>
            <IonSpinner />
          </div>
      </div>


      </div>
      <div className="c-form-div">
      <b>
          <p>Nog geen account? <IonRouterLink routerLink="/Registreren" className="c-login-geen_acc primary-underline no-decoration">Registreer</IonRouterLink> je nu!
          </p>
          <p><IonRouterLink routerLink="/Wachtwoordvergeten" className="primary-underline no-decoration">Wachtwoord vergeten?</IonRouterLink></p>
        </b>
      </div>
    </div>
  );
};

export default LoginForm;
