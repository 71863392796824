import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Storage } from '@capacitor/storage';
import API_URL from '../utils/api';
import { IonButton, IonRouterLink, IonSpinner } from '@ionic/react';

const WWVergetenForm: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [info, setInfo] = useState<string>('');
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [spinner, setSpinner] = useState<string>('c-spinner-hide');
  const handleSubmit = async () => {
    if(!isRunning){
      setSpinner('c-spinner');
      setIsRunning(true);
  
      await fetch(`${API_URL}/forgotpassword?email=${email}`)
      .then(async (response) => {
          if(!response.ok){
            throw await response.text();
          }
          setInfo(await response.text());
          setSpinner('c-spinner-hide');
          document.getElementById('forgot-input-success').style.display ='flex';
          document.getElementById('forgot-input-complete').style.display ='none';
          setIsRunning(false);
        })
        .catch((error) => {
          setSpinner('c-spinner-hide');
          if(error.message){
            setError(error.message);
          }
          else{
            setError(error);
          }
          setIsRunning(false);
        });
    }
    
  };
  return (
    <div className="container container-login background">
      <img src="assets/images/recreatievezeevisserij-contrast.png" className="c-login-logo-registreren" alt="logo recvis" />
      <div className="c-form-div">
      <p className="c-form-error">{error}</p>
        <div id='forgot-input-success' className='c-form-item' style={{display: "none"}} >
          <p className="c-form-info">{info}</p>
        </div>
        <div id='forgot-input-complete' className='full-width'>
        <div className="c-form-item">
          <label htmlFor="" className="c-form-label">
            E-mail
          </label>
          <input type="text" className="c-form-input" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className='c-form-item register-btn-div'> 
          <IonButton  onClick={() => handleSubmit()}>E-mail verzenden</IonButton>
        </div>
        </div>

        <div className={spinner}>
            <IonSpinner />
          </div>
      </div>
      <div className="c-form-div">
      <b>
          <p>Terug naar <IonRouterLink routerLink="/Login" className="primary-underline no-decoration">Login</IonRouterLink></p>
        </b>
      </div>
    </div>
  );
};

export default WWVergetenForm;
