import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import VangstToevoegenScreen from '../components/VangstToevoegenScreen';

const VangstToevoegen: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Vangst toevoegen</IonTitle>
          <img slot="end" className="c-header-logo" src="assets/images/recvis.png" alt="logo recvis" />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <VangstToevoegenScreen />
      </IonContent>
    </IonPage>
  );
};

export default VangstToevoegen;
