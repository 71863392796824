import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import AccountComponent from '../components/Account';

const Account: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Profiel</IonTitle>
          <img slot="end" className="c-header-logo" src="assets/images/recvis.png" alt="logo recvis" />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <AccountComponent />
      </IonContent>
    </IonPage>
  );
};

export default Account;
