import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import React, { useState } from 'react';
import { setupIonicReact } from '@ionic/react';
import Menu from './components/Menu';
import Login from './pages/Login';
import Registreren from './pages/Registreren';
import WwVergeten from './pages/WWVergeten';
import WwReset from './pages/WWReset';
import VEmail from './pages/EmailValidate';
import Logout from './pages/Logout';
import Home from './pages/Home';
import Account from './pages/Account';
import Overzicht from './pages/Overzicht';
import Logboek from './pages/Logboek';
import VangstToevoegen from './pages/VangstToevoegen';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './style/screen.scss';

// setupIonicReact({
//   mode: 'ios'
// });

const App: React.FC = () => {
  const { Storage } = Plugins;
  const [authenticated, setAuthenticated] = useState(false);

  setupIonicReact({
    //mode: 'md'
  });

  const getItem = async () => {
    const value = await Storage.get({ key: 'Logged-in' });
    if (value.value == 'true') {
      setAuthenticated(true);
    }
  };
  getItem();

  return (
      <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Switch>
              <Route path="/" exact={true}>
                <Redirect to="/Home" />
              </Route>
              <Route path="/Login" exact={true}>
                <Login />
              </Route>
              <Route path="/Registreren" exact={true}>
                <Registreren />
              </Route>
              <Route path="/Wachtwoordvergeten" exact={true}>
                <WwVergeten />
              </Route>
              <Route path="/Wachtwoordreset">
                <WwReset />
              </Route>
              <Route path="/Valideer-email">
                <VEmail />
              </Route>
              <Route path="/Logout" exact={true} >
                <Logout />
              </Route>
              <Route path="/Home" exact={true}>
                <Home />
              </Route>
              <Route path="/Account" exact={true}>
                <Account />
              </Route>
              <Route path="/Overzicht" exact={true}>
                <Overzicht />
              </Route>
              <Route path="/Logboek/:id?">
                <Logboek />
              </Route>
              <Route path="/VangstToevoegen" exact={true}>
                <VangstToevoegen />
              </Route>
            </Switch>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
    
  );
};

export default App;
