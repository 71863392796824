import API_URL from './api';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

const dataAccess = (function () {
  const get = (endpoint: string) => {
    return fetch(`${API_URL}/${endpoint}`)
      .then((r) => r.json())
      .then(async (data) => {
        await Storage.set({
          key: `${endpoint}`,
          value: JSON.stringify(data),
        });
        return data;
      })
      .then((data) => data)
      .catch(async (error) => {
        console.log(error);
        const ret = await Storage.get({ key: `${endpoint}` });

        // @ts-ignore
        const data = JSON.parse(ret.value);
        console.log(data);
        if (data.length != 0) {
          return data;
        }
      });
  };
  return {
    get: get,
  };
})();

export default dataAccess;
