import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote } from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { homeOutline, homeSharp, logOutOutline, logOutSharp, personOutline, personSharp, readerOutline, readerSharp } from 'ionicons/icons';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Home',
    url: '/Home',
    iosIcon: homeOutline,
    mdIcon: homeSharp,
  },
  {
    title: 'Logboeken',
    url: '/Overzicht',
    iosIcon: readerOutline,
    mdIcon: readerSharp,
  },
  {
    title: 'Profiel',
    url: '/Account',
    iosIcon: personOutline,
    mdIcon: personSharp,
  },
  {
    title: 'Afmelden',
    url: '/Logout',
    iosIcon: logOutOutline,
    mdIcon: logOutSharp,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  //console.log(location.pathname);
  if (location.pathname == '/' 
  || location.pathname.toLowerCase() == '/login' 
  || location.pathname.toLowerCase() == '/logout' 
  || location.pathname.toLowerCase() == '/registreren' 
  || location.pathname.toLowerCase() == '/wachtwoordvergeten' 
  || location.pathname.toLowerCase() == '/wachtwoordreset'
  || location.pathname.toLowerCase() == '/valideer-email' 
  ) {
    return <></>;
  } else {
    return (
      <IonMenu contentId="main" type="overlay" swipeGesture={true}  >
        <IonContent >
        <img className="c-menu-logo" src="assets/images/recreatievezeevisserij.png" alt="logo recvis" />

          <IonList id="inbox-list">
            {/* <IonListHeader>MENU</IonListHeader> */}
            {appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false} >
                  <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="full" detail={false}>
                    <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
        </IonContent>
      </IonMenu>
      
    );
  }
};

export default Menu;
