import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButtons, IonContent, IonLabel, IonDatetime, IonItem, IonIcon, IonSpinner, IonButton, IonPage, IonAlert, IonInput, IonList, IonRadioGroup, IonRadio, IonCheckbox, useIonAlert, IonSelect, IonSelectOption, IonModal, IonPopover, IonDatetimeButton  } from '@ionic/react';
import { addOutline, closeOutline, chevronBackOutline, locate, help, helpCircle, ellipsisVertical, addCircle } from 'ionicons/icons';
// import { useIonAlert } from '@ionic/react';
import API_URL from '../utils/api';
import dataAccess from '../utils/fetch';
import { Storage } from '@capacitor/storage';
import { useStorage } from '@capacitor-community/react-hooks/storage'
import coastbustersimg from '../assets/images/Coastbusterssites2021.jpg';
import DEVICE from '../utils/device';


interface PropsProgressbar {
  progressbar: any;
  id: string;
}
interface PropsForm {
  currentStep: number;
  code: string;
  datum?: Date;
  duur?: number;
  aas?: string;
  hengel?: string;
  country?: string;
  show?: any;
  readonly: boolean;
}
interface data {
  id: string;
  name: string;
  variable: any;
}
interface vis {
  id: string;
  measure: string
  name: string;
  place: string;
}
interface formDataEdit {
  code: string;
  datum: Date;
  duur: number;
  aas: string;
  land: string;
  locaties: [];
  techniek: string;
  visvangst: [];
}
const Logboek: React.FC<PropsProgressbar> = (PropsProgressbar) => {
  // var tmp = useStorage().get('code');
  const [present] = useIonAlert();
  //UseState maken voor het tellen op welke stap de gebruiker is
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [code, setCode] = useState<string>('');
  const [id, setId] = useState<string>(PropsProgressbar.id == undefined ? '' : PropsProgressbar.id);
  const [showButtons, setShowButtons] = useState<string>('false');
  // const [dataEdit, setDataEdit] = useState<formDataEdit[]>([]);
  const [spinner, setSpinner] = useState<string>('c-spinner-hide');
  const [logboekOpen, setLogboekOpen] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  //History functie wordt opgeroepen voor de stack van de screnen te veranderen
  const history = useHistory();
  var [initialLoad, setInitialLoad] = useState<boolean>(true);

  const [presentAlert] = useIonAlert();

  //check if logged in
  const loadInitial = (): void => {
    Storage.get({ key: 'id' }).then((value: any) => {
      if(value.value == undefined || value.value == null){
        setId('');
      }

    Storage.get({ key: 'code' }).then((codeValue: any) => {
      if(codeValue != ''){
        setCode(codeValue.value);
        // Storage.get({ key: `${code}_${id}` }).then(async (result: any) => {
        //   let data = JSON.parse(result.value);
        //   if(data.)
        // });

        setInitialLoad(false);
      }
      else
      {
        const location = {
        pathname: '/login',
        state: { fromDashboard: true },
        };
        history.replace(location);
      }

      Storage.get({ key: `admin` }).then((isAdm: any) => {
        if(isAdm.value === "false" && id !== ''){
        
          Storage.get({ key: `userCode` }).then((userCode: any) => {
            // @ts-ignore
           Storage.get({ key: `${userCode.value}_${value.value}` }).then((logboekValue: any) => {
           var lb = JSON.parse(logboekValue.value);
           var d = new Date(lb.datum);

           var dateNow = new Date();

            const diffTime = Math.abs(dateNow.getTime() - d.getTime());
            const diffDays = diffTime / (1000 * 60 * 60 * 24); 

          if(diffDays >= 15){
            setIsReadOnly(true);

          }
          });
         });
        }
      });
    });
    });

  }
  // (async function () {
  //   const { value } = await Storage.get({ key: 'code' });
  //   // @ts-ignore
  //   setCode(value);
  //   const location = {
  //     pathname: '/login',
  //     state: { fromDashboard: true },
  //   };
  //   if (value == null) {
  //     history.replace(location);
  //   }
  // })();
  //Afsluiten functie wordt gebruikt bij de afsluiten knop op lijn 38
  const Afsluiten = () => {
    history.goBack();
  };
  // const datatoevoegen = (result: any) => {
  //   const newList = dataEdit.concat(result);
  //   console.log(newList);
  //   setDataEdit(newList);
  // };
  // const stappen = async () => {
  //   if (typeof PropsProgressbar.id !== 'undefined') {
  //     const { value } = await Storage.get({ key: 'token' });
  //     var myHeaders = new Headers();
  //     myHeaders.append('Authorization', `Bearer ${value}`);

  //     var requestOptions = {
  //       method: 'GET',
  //       headers: myHeaders,
  //     };

  //     await fetch(`${API_URL}/logboek?id=${PropsProgressbar.id}`, requestOptions)
  //       .then((response) => response.json())
  //       .then((result) => datatoevoegen(result))
  //       .catch((error) => console.log('error', error));
  //   }
  //   return (
  //     <>
  //       <Stap1 currentStep={currentStep} code={code} datum={dataEdit[0].datum} duur={dataEdit[0].duur} />
  //       <Stap2 currentStep={currentStep} code={code} aas={dataEdit[0].aas} />
  //       <Stap3 currentStep={currentStep} code={code} hengel={dataEdit[0].techniek} />
  //       <Stap4 currentStep={currentStep} code={code} country={dataEdit[0].land} />
  //       <Stap5 currentStep={currentStep} code={code} />
  //       <Stap6 currentStep={currentStep} code={code} show={setShowButtons} />
  //     </>
  //   );
  // };
  //Hier wordt de progressbar in NieuwLogboek teruggestuurd met de actuele stap waarop de gebruiker is
  useEffect(() => {
    if(initialLoad){
      loadInitial();
    }
    PropsProgressbar.progressbar(currentStep / 6);
  }, [currentStep, code, id]);

  const previousButton = () => {
    //Als de momentele stap kleiner is als het totaal aantal stappen dan komt de "Vorige" knop er zoniet komt de "Afsluiten" knop er
    //Deze functie wordt opgeroepen in het form lijn 74
    if (showButtons == 'false') {
      if (currentStep !== 1) {
        return (
          // <button className="c-button-full" type="button" onClick={() => previous()}>
          //   Vorige
          // </button>
          <IonButton color="primary" onClick={() => previous()}>Vorige</IonButton>
        );
      }
      return (
        <IonButton color="primary" onClick={() => Afsluiten()}>Terug</IonButton>
      );
    }
  };
  const nextButton = () => {
    //Als de momentele stap kleiner is als het totaal aantal stappen dan komt de "Volgende" knop er, zoniet komt de "Voltooien" knop er
    //Deze functie wordt opgeroepen in het form lijn 76
    if (showButtons == 'false') {
      if (currentStep < 6) {
        return (
          <IonButton color="primary" onClick={() => Next()}>Volgende</IonButton>
        );
      }
      else if(isReadOnly)
      {
        return (
          <IonButton color="primary" onClick={() => Afsluiten()}>Sluiten</IonButton>
        );
        
      }
      return (
        <IonButton color="primary" onClick={() => CheckIfReady()}>Versturen</IonButton>
      );
    }
  };
  const changeProgress = (stepNr: number) => {
    //Momentele stap verminderen met 1 als het niet kleiner of gelijk is aan 1
    //Deze functie wordt gebruikt bij de previousButton functie
    setCurrentStep(stepNr);
    document.getElementById("progress-step").innerHTML = `Stap ${stepNr}/6`;
    
  };

  const previous = () => {
    //Momentele stap verminderen met 1 als het niet kleiner of gelijk is aan 1
    //Deze functie wordt gebruikt bij de previousButton functie
    changeProgress(currentStep <= 1 ? 1 : currentStep - 1);
  };
  const Next = async () => {
    //Momentele stap vermeerderen met 1 als het niet groter of gelijk is aan het totaal aantal stappen - 1
    //Deze functie wordt gebruikt bij de nextButton functie
    var ret = await Storage.get({ key: `${code}_${id}` });
    // @ts-ignore
    var data = JSON.parse(ret.value);
    //  console.log(data);
     if(currentStep == 1){
       var tmpDate = new Date();
      if(new Date(data.datum) > tmpDate){
        present({
          cssClass: 'alert-css',
          header: 'Opgelet',
          message: 'Een datum in de toekomst wordt niet geaccepteerd',
          buttons: [
            'ok'
          ]
        })

        return;
       }
       if(data.duur <= 0){
        present({
          cssClass: 'alert-css',
          header: 'Opgelet',
          message: 'Gelieve een geldige tijdsduur in te geven',
          buttons: [
            'ok'
          ]
        })
        return;
       }
     }
     if(currentStep == 2){
      if(!data.aas){
        present({
          cssClass: 'alert-css',
          header: 'Opgelet',
          message: 'Gelieve een type aas te selecteren',
          buttons: [
            'ok'
          ]
        })
       return;
      }
    }
    if(currentStep == 3){
     if(!data.techniek){
       present({
         cssClass: 'alert-css',
         header: 'Opgelet',
         message: 'Gelieve een vistechniek te selecteren',
         buttons: [
           'ok'
         ]
       })
      return;
     }
   }
   if(currentStep == 5){
    if(data.locaties.length == undefined || data.locaties.length === 0){
      present({
        cssClass: 'alert-css',
        header: 'Opgelet',
        message: 'Gelieve 1 of meerdere locaties te kiezen',
        buttons: [
          'ok'
        ]
      })
     return;
    }
  }
    changeProgress(currentStep >= 5 ? 6 : currentStep + 1);
  };

  const CheckIfReady = async () => {
    const ret = await Storage.get({ key: `${code}_${id}` });
    const { value } = await Storage.get({ key: 'token' });
    // @ts-ignore
    const data = JSON.parse(ret.value);
    // console.log(data);
    if(!data.visvangst || data.visvangst.length === undefined || data.visvangst.length === 0){
      presentAlert({
        header: 'Waarschuwing',
        message: 'Dit logboek bevat geen visvangst gegevens. Bent u zeker dat u het logboek wilt versturen?',
        cssClass: 'custom-alert',
        buttons: [
          {
            text: 'Nee',
            cssClass: 'alert-button-cancel',
          },
          {
            text: 'Ja',
            cssClass: 'alert-button-confirm',
            handler: () => {
              UploadForm();
            },
          },
        ],
      })
    }
    else
    {
      UploadForm();
    }
  };
  const UploadForm = async () => {

      const ret = await Storage.get({ key: `${code}_${id}` });
      const { value } = await Storage.get({ key: 'token' });
      // @ts-ignore
      const data = JSON.parse(ret.value);
      // console.log(data);
      if(!data.visvangst){
        data.visvangst = [];
      }

    setSpinner('c-spinner');

    if (data.datum == '' || data.duur == 0 || data.aas == '' || data.techniek == '' || data.locaties.length === 0 || data.land == '') {
      setLogboekOpen(true);
      setSpinner('c-spinner-hide');
    } else {
      if(!data.visvangst.length){
        data.visvangst = [];
      }
      data.build = DEVICE;
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + value);
      myHeaders.append('Content-Type', 'application/json');
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
      };
      const completePost = async () => {
        await Storage.remove({ key: `${code}_${id}` });
        setSpinner('c-spinner-hide');
        // todo show a message
      };
      await fetch(`${API_URL}/postlogboek?id=${id}`, requestOptions)
      //await fetch(`https://localhost:44341/recvis/postlogboek?id=${id}`, requestOptions)
        .then((response) => {
          if(response.ok){
            completePost();
            present({
              cssClass: 'alert-css',
              header: 'Logboek verzonden!',
              message: 'We hebben uw logboek correct ontvangen. Wilt u een nieuw logboek aanmaken?',
              backdropDismiss: false,
              buttons: [{
                text: 'Nee',
                cssClass: 'alert-button-cancel',
                handler: () => {
                  history.push('/Overzicht');
                },
              },
              {
                text: 'Ja',
                cssClass: 'alert-button-confirm',
                handler: () => {
                  nieuwe();
                },
              },
              ]
            });
            // @ts-ignore
            //document.getElementById('logboek-confirm-message').style.display ='none';
            //document.getElementById('wizard-buttons').style.display ='none';
            //document.getElementById('wizard-complete-buttons').style.display ='flex';
            //document.getElementById('logboek-received-message').style.display ='flex';
          }
          else{
            
            response.text().then(text => {
              present({
                cssClass: 'alert-css',
                header: 'Opgelet',
                message: text,
                buttons: [
                  'ok'
                ]
              });
            })
            
          }
          setSpinner('c-spinner-hide');

        })
        .catch((error) => {
          console.error('Error', error);
          setSpinner('c-spinner-hide');

        })
    }
  };

  const afsluiten = () => {
    history.push('/Overzicht');
  }

  const nieuwe = async () => {
    let locaties: [];
      let vv: [];
    const data = { code: code, datum: '', duur: 0, aas: '', land: '72dad0d1-a614-4351-b90e-966c83c264ab', locaties: locaties, techniek: '', visvangst: vv };
      await Storage.set({
        key: `${code}_`,
        value: JSON.stringify(data),
      });
    document.location.href = document.location.href;
  }
  return (
    <div className="container container-logboek container-loggedIn">
      <form className="c-form-div-logboek">
        <IonAlert
          isOpen={logboekOpen}
          onDidDismiss={() => setLogboekOpen(false)}
          cssClass="my-custom-class"
          header={'Opgelet'}
          message={'Gelieve alle velden in te vullen!'}
          buttons={[
            {
              text: 'Ok',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                setLogboekOpen(false);
              },
            },
          ]}
        />
        {/* {stappen} */}
        <Stap1 currentStep={currentStep} code={code} datum={new Date()} duur={0} readonly={isReadOnly} />
        <Stap2 currentStep={currentStep} code={code} aas={''} readonly={isReadOnly}/>
        <Stap3 currentStep={currentStep} code={code} hengel={''} readonly={isReadOnly}/>
        <Stap4 currentStep={currentStep} code={code} country={''} readonly={isReadOnly}/>
        <Stap5 currentStep={currentStep} code={code} readonly={isReadOnly}/>
        <Stap6 currentStep={currentStep} code={code} readonly={isReadOnly}/>
        {/* <Stap7 currentStep={currentStep} code={code} show={setShowButtons} readonly={isReadOnly}/> */}
        <div className={spinner}>
          <IonSpinner />
        </div>
        <div className="c-form-buttons-div" id='wizard-buttons'>
          {previousButton()}
          <div className="c-form-button-whitespace"></div>
          {nextButton()}
        </div>
        <div className="c-form-buttons-div hidden"  id='wizard-complete-buttons'>
          <IonButton color="primary" onClick={() => afsluiten()}>Afsluiten</IonButton>
          <div className="c-form-button-whitespace"></div>
          <IonButton color="primary" onClick={() => nieuwe()}>Nieuwe maken</IonButton>
        </div>
      </form>
    </div>
  );
};

const Stap1: React.FC<PropsForm> = (PropsForm) => {
  var [isAdmin, setIsAdmin] = useState<boolean>(false);
  var [user, setUser] = useState<string>('');
  var [datum, setDatum] = useState<Date>(new Date());
  var [duur, setDuur] = useState<Date>(new Date(new Date(2022, 1, 1, 0, 0, 0, 0).getTime() - new Date().getTimezoneOffset() * 60000 ));
  var [initialLoad, setInitialLoad] = useState<boolean>(true);
  const loadInitial = (): void => {
    Storage.get({ key: `admin` }).then((admvalue: any) => {
      let tmpAdm = JSON.parse(admvalue.value);
      setIsAdmin(tmpAdm);
      if(!tmpAdm){
        setUser(PropsForm.code);
      }
    });
    Storage.get({ key: `code` }).then((value: any) => {
      if(value){
        Storage.get({ key: `id` }).then((idval: any) => {
          if(idval){
            Storage.get({ key: `${value.value}_${idval.value}` }).then(async (result: any) => {
              let data = JSON.parse(result.value);

              

              if(data === null){
                data = { land: '72dad0d1-a614-4351-b90e-966c83c264ab'};
              }

              if(!data.datum){
                setDatum(new Date(new Date().toUTCString().slice(0, -4)));
                data.datum = new Date(new Date().toUTCString().slice(0, -4));
              }
              else
              {
                setDatum(new Date(data.datum))
              }
              
              if(data.duur === undefined){
                var newDate = new Date(2022, 1, 1, 0, 0, 0, 0 );
                setDuur(newDate);
                data.duur = 0;
              }
              else{
                setDuur(new Date(new Date(2022, 1, 1, 0, 0, 0, 0).getTime() + data.duur*60*1000));
              }

              if(data.code){
                setUser(data.code);
              }
              
              
              setInitialLoad(false);
              await Storage.set({
                key: `${value.value}_${idval.value}`,
                value: JSON.stringify(data),
              });
            });
          }
        })
      }
    });
}
  useEffect(() => {
    (async function () {
      if(initialLoad){
        loadInitial();
      }
      else{
        const code = await Storage.get({ key: `code` });
        const id = await Storage.get({ key: `id` });
        const ret = await Storage.get({ key: `${code.value}_${id.value}`});
        if (ret.value != 'null') {
          // @ts-ignore
          let data = JSON.parse(ret.value);
        
          // datum = new Date(data['datum']);
          // duur = new Date(data['duur']);
  
          let totalDuur = duur.getHours() * 60 + duur.getMinutes(); 
  
  
          data['datum'] = datum;
          data['duur'] = totalDuur;
          data['code'] = user;
  
          await Storage.set({
            key: `${PropsForm.code}_${id.value}`,
            value: JSON.stringify(data),
          });
        }
      }
      
    })();
  }, [datum, duur, user]);
  if (PropsForm.currentStep !== 1) {
    // Prop: The current step
    return null;
  }

  const formatDuurString = (date: Date) => {
    //console.log("format duur");
    var hour = date.getHours();
    var minutes = date.getMinutes();

    return "0000-01-01T" + String(hour).padStart(2, '0') + ":" + String(minutes).padStart(2, '0') + ":00";
  };

  const convertToTime = (val: String) => {
    //console.log("convert date");
    var time = val.split('T')[1];
    var hours = time.split(':')[0];
    var minutes = time.split(':')[1];
    var tempDuur = new Date(new Date(2022, 1, 1, parseInt(hours), parseInt(minutes), 0, 0).getTime());

    return tempDuur;
  };
  // The markup for the Step 1 UI
  return (
    <>
      {/* <h1 className="c-logboek-titel">Info</h1> */}
      <div className="c-form-item c-form-item-logboek">
        {
          isAdmin ? 
            <div className="logboek-row  margin-bottom-20">
        <div className="logboek-column">
          <div className='column-label'>
          E-mail of identificatiecode
          </div>
        </div>
        <div className="logboek-column">
        <div className='full-width'>
          <input className="c-form-input input-right input-right-long" id="Identificatienummer" name="Identificatienummer" type="text" disabled={PropsForm.readonly} value={user} onChange={(e) => setUser(e.target.value)} />
          </div>
        </div>
      </div>
          :
          ""
        }
<label className="c-form-label margin-bottom-10" htmlFor="Datum">
          Datum en tijdsduur
        </label>

      <div className="logboek-row">
          <div className='logboek-column column-label'>
            Datum visvangst
          </div>
        <div className="logboek-column">
          <div className='full-width'>

          <IonDatetimeButton datetime="date-datetime" disabled={PropsForm.readonly} className='c-form-input-ion c-form-input c-input-date input-right'></IonDatetimeButton>
      
      <IonModal keepContentsMounted={true}>
      <IonDatetime id="date-datetime" className="c-form-input-ion c-form-input c-input-date input-right" presentation='date' locale="nl-BE" firstDayOfWeek={0} min={new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()} max={new Date().toISOString()} value={new Date(datum.getTime() - (datum.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0]} disabled={PropsForm.readonly} 
                    onIonChange={(e) => setDatum(new Date(e.detail.value.toString()))}></IonDatetime>
      </IonModal>
                    </div>
        </div>
      </div>
      <div className="logboek-row">
          <div className='logboek-column column-label'>
            Duur (uu:mm)
          </div>
        <div className="logboek-column">
          <div className='full-width'>
          <IonDatetimeButton datetime="time-datetime" disabled={PropsForm.readonly} className='c-form-input-ion c-form-input c-input-date input-right'></IonDatetimeButton>
          
      <IonModal keepContentsMounted={true}>
      <IonDatetime id="time-datetime" className="" presentation='time' locale="nl-BE"  minuteValues="0,15,30,45" hourCycle='h23' value={formatDuurString(duur)} onIonChange={(e) => setDuur(convertToTime(e.detail.value.toString()))} disabled={PropsForm.readonly} ></IonDatetime>

      </IonModal>
</div>
        </div>
      </div>
      </div>
    </>
  );
};
const Stap2: React.FC<PropsForm> = (PropsForm) => {
  const [aas, setAas] = useState<string>('');
  const [azen, setAzen] = useState<data[]>([]);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const loadInitial = (): void => {
    Storage.get({ key: `code` }).then((value: any) => {
      
      if(value){
        Storage.get({ key: `id` }).then((id: any) => {
          Storage.get({ key: `${value.value}_${id.value}` }).then((result: any) => {
            let data = JSON.parse(result.value);
            if(data != null && data.aas){
              setAas(data.aas);
            }
            
            setInitialLoad(false);
          });
        })
        
        
      }
      else
      {
        setInitialLoad(true);
      }
    });
  }
  useEffect(() => {
    (async function () {
      if(initialLoad){
        loadInitial();
      }
      else
      {
        const code = await Storage.get({ key: `code` });
        const id = await Storage.get({ key: `id` });
        const ret = await Storage.get({ key: `${code.value}_${id.value}` });
        if (ret.value != 'null') {
          var data = {};
          // @ts-ignore
          data = JSON.parse(ret.value);
          // @ts-ignore
          data['aas'] = aas;
          await Storage.set({
            key: `${code.value}_${id.value}`,
            value: JSON.stringify(data),
          });
        }
      }
      
    })();
  }, [aas, initialLoad]);
  useEffect(() => {
    (async function () {
      const data = await dataAccess.get('rodtypes');
      setAzen(data);
    })();
  }, []);

  if (PropsForm.currentStep !== 2) {
    // Prop: The current step
    return null;
  }
  // The markup for the Step 2 UI
  return (
    <>
      {/* <h1 className="c-logboek-titel">Vistechniek</h1> */}
      <div className="c-form-item">
        <label className="c-form-label">Type aas</label>
        <IonList className="margin-left-minus-20">

          <IonRadioGroup value={aas} onIonChange={e => setAas(e.detail.value)}>
          {azen.map((message) => (
            <IonItem key={message.id}>
                <IonLabel>{message.name}</IonLabel>
                <IonRadio slot="start" value={message.id} disabled={PropsForm.readonly}/>
            </IonItem>
          ))}
          </IonRadioGroup>
        </IonList>
      </div>
    </>
  );
};
const Stap3: React.FC<PropsForm> = (PropsForm) => {
  const [visTechnieken, setVisTechnieken] = useState<data[]>([]);
  const [vistechniek, setVisTechniek] = useState<string>('');
  const [passieveStrandvisserij, setPassieveStrandvisserij] = useState<string>('');
  const [aantal, setAantal] = useState<number>(0);
  const [passieveStrandvisserijTypes, setPassieveStrandvisserijTypes] = useState<data[]>([]);

  const [showAdditionalControls, setShowAdditionalControls] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const loadInitial = (): void => {

    Storage.get({ key: `code` }).then((value: any) => {
      if(value){
        Storage.get({ key: `id` }).then((id: any) => {
          Storage.get({ key: `${value.value}_${id.value}` }).then((result: any) => {
            let data = JSON.parse(result.value);
            if(data != null && data.techniek){
              setVisTechniek(data.techniek);
            }
            setInitialLoad(false);
          });
        });
      }
      else
      {
        setInitialLoad(true);
      }
    });
  }
  useEffect(() => {
    (async function () {
      if(initialLoad){
        loadInitial();
      }
      else
      {
        let pTypes = await dataAccess.get('beachfishingtypes');
        setPassieveStrandvisserijTypes(pTypes);

        const code = await Storage.get({ key: `code` });
        const id = await Storage.get({ key: `id` });
        const ret = await Storage.get({ key: `${code.value}_${id.value}` });
        if (ret.value != 'null') {
          let data = {};
          // @ts-ignore
          data = JSON.parse(ret.value);
          // @ts-ignore
          data['techniek'] = vistechniek;
          // @ts-ignore
          data['passieveStrandvisserij'] = passieveStrandvisserij;
          // @ts-ignore
          data['aantal'] = aantal;
          if(vistechniek === 'da34801e-fa86-4494-ab9e-3519ecfc66ce'){
            setShowAdditionalControls(true);
          }
          else{
            setShowAdditionalControls(false);
          }
          
          await Storage.set({
            key: `${code.value}_${id.value}`,
            value: JSON.stringify(data),
          });
        }
      }
      
    })();
  }, [vistechniek, passieveStrandvisserij, aantal , initialLoad]);
  useEffect(() => {
    (async function () {
      const data = await dataAccess.get('techniques');
      setVisTechnieken(data);
    })();
  }, []);

  // const ListVisTechniekGen = ({ name, id, variable }: data) => {
  //   return (
  //     <div className="c-form-radio-item-div-vertical">
  //       <input type="radio" id={name} name="type" value={name} onChange={(e) => variable(e.target.value)} />
  //       <label className="c-form-radio-label-vertical" htmlFor={name}>
  //         {name}
  //       </label>
  //     </div>
  //   );
  // };
  if (PropsForm.currentStep !== 3) {
    // Prop: The current step
    return null;
  }
  // The markup for the Step 3 UI
  return (
    <>
      {/* <h1 className="c-logboek-titel">Vistechniek</h1> */}
      <div className="c-form-item">
        <label className="c-form-label" htmlFor="type">
          Vistechniek
        </label>
        <IonList className="margin-left-minus-20">

          <IonRadioGroup value={vistechniek} onIonChange={e => setVisTechniek(e.detail.value)}>
          {visTechnieken.map((message) => (
            <IonItem key={message.id}>
                <IonLabel className='ion-text-wrap'>{message.name}</IonLabel>
                <IonRadio slot="start" disabled={PropsForm.readonly} value={message.id} />
            </IonItem>
          ))}
          </IonRadioGroup>
          
        </IonList>
        {
            showAdditionalControls ? 
            <div className='full-width'>
              <div className="logboek-row">
        
          <div className='full-width'>
                {/* <IonLabel>Type:</IonLabel> */}
                {/* <IonRadio slot="start" value={country} /> */}
                <IonSelect value={passieveStrandvisserij} className='full-width padding-left-0 div-left' placeholder="Selecteer een type" onIonChange={e => setPassieveStrandvisserij(e.detail.value)}>
                {passieveStrandvisserijTypes.map((message) => (
                    <IonSelectOption key={message.id} value={message.id} disabled={PropsForm.readonly}>{message.name}</IonSelectOption>
                  ))}
                </IonSelect>
          </div>
      </div>
      <div className="logboek-row">
        <div className="logboek-column">
          <div className='column-label'>
            Aantal
          </div>
        </div>
        <div className="logboek-column">
          <div className='full-width'>
          <input className="c-form-input input-right" id="strandvisserijaantal" name="strandvisserijaantal" type="number" min="0" value={aantal} onChange={(e) => setAantal(parseInt(e.target.value))} />
          </div>
        </div>
      </div>
            </div> : ""
          }
      </div>
    </>
  );
};
const Stap4: React.FC<PropsForm> = (PropsForm) => {
  const [countries, setCountries] = useState<data[]>([]);
  const [countriesSep, setCountriesSep] = useState<data[]>([]);
  const [country, setcountry] = useState<string>('72dad0d1-a614-4351-b90e-966c83c264ab');
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const loadInitial = (): void => {
    Storage.get({ key: `code` }).then((value: any) => {
      
      if(value){
        Storage.get({ key: `id` }).then((id: any) => {
          Storage.get({ key: `${value.value}_${id.value}` }).then((result: any) => {
            let data = JSON.parse(result.value);
            if(data != null && data.land){
              setcountry(data.land);
            }
            
            setInitialLoad(false);
          });
        })
      }
      else
      {
        setInitialLoad(true);
      }
    });
  }

  useEffect(() => {
    (async function () {
      let countries = await dataAccess.get('countries');

      countriesSep.length = 0;
      // @ts-ignore
      countries.forEach((land) => {
        if (land.showInList == false) {
          setCountriesSep((countriesSep) => [...countriesSep, land]);
        } else {
          setCountries((countries) => [...countries, land]);
        }
      });
    })();
  }, []);
  useEffect(() => {
    (async function () {
      if(initialLoad){
        loadInitial();
      }
      else
      {
        const code = await Storage.get({ key: `code` });
        const id = await Storage.get({ key: `id` });
        const ret = await Storage.get({ key: `${code.value}_${id.value}` });
        if (ret.value != 'null') {
          let data = {};
          // @ts-ignore
          data = JSON.parse(ret.value);
          // @ts-ignore
          data['land'] = country;
          await Storage.set({
            key: `${code.value}_${id.value}`,
            value: JSON.stringify(data),
          });
        }
      }

      
    })();
  }, [country]);
  // const listCountriesGen = () => {
  //   return (
  //     <>
  //       {countries.map((message) => (
  //         <option key={message.id} className="c-form-select-option" value={message.id}>
  //           {message.name}
  //         </option>
  //       ))}
  //     </>
  //   );
  // };

  if (PropsForm.currentStep !== 4) {
    // Prop: The current step
    return null;
  }
  // The markup for the Step 4 UI
  return (
    <>
      {/* <h1 className="c-logboek-titel">Land Visvangst</h1> */}
      <div className="c-form-item">
        <label className="c-form-label" htmlFor="land">
          Land
        </label>
        <IonList className="margin-left-minus-20">

          <IonRadioGroup value={country} onIonChange={e => setcountry(e.detail.value)}>
          {countriesSep.map((message) => (
            <IonItem key={message.id}>
                <IonLabel>{message.name}</IonLabel>
                <IonRadio slot="start" value={message.id} disabled={PropsForm.readonly}/>
            </IonItem>
          ))}
          
          </IonRadioGroup>
        </IonList>
        <div className='logboek-row'>
{/* <IonRadio slot="start" value={country} /> */}
<IonSelect value={country} className='div-left padding-left-0 full-width' placeholder="Selecteer een ander land" onIonChange={e => setcountry(e.detail.value)}>
                {countries.map((message) => (
                    <IonSelectOption key={message.id} value={message.id} disabled={PropsForm.readonly}>{message.name}</IonSelectOption>
                  ))}
                </IonSelect>
        </div>
                
        </div>
    </>
  );
};
const Stap5: React.FC<PropsForm> = (PropsForm) => {
  const [locations, setLocations] = useState<data[]>([]);
  const [location, setLocation] = useState<any[]>([]);
  const [country, setcountry] = useState<string>('');
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [showModal, setShowModal] = useState(false);

  const loadInitial = (): void => {
    Storage.get({ key: `code` }).then((value: any) => {
      
      if(value){
        Storage.get({ key: `id` }).then((id: any) => {
          Storage.get({ key: `${value.value}_${id.value}` }).then((result: any) => {
            let data = JSON.parse(result.value);
            if(data != null && data.locaties){
              setLocation(data.locaties);
            }
            if(data != null && data.land){
              setcountry(data.land);
            }
            
            setInitialLoad(false);
          });
        })
        
        
      }
      else
      {
        setInitialLoad(true);
      }
    });
  }
  const showCoastBustersImg = (e: React.MouseEvent<HTMLIonIconElement, MouseEvent>): void => {
    setShowModal(true);

    e.preventDefault();
  }

  useEffect(() => {
    (async function () {

      if(initialLoad){
        loadInitial();
      }
      else
      {
        const code = await Storage.get({ key: `code` });
        const id = await Storage.get({ key: `id` });
        const ret = await Storage.get({ key: `${code.value}_${id.value}` });
        if (ret.value != 'null') {
          let data = {};
          // @ts-ignore
          data = JSON.parse(ret.value);
          // @ts-ignore
          data['locaties'] = location;
          await Storage.set({
            key: `${code.value}_${id.value}`,
            value: JSON.stringify(data),
          });
        }
      }
      
    })();
  }, [location]);
  useEffect(() => {
    (async function () {
      let locations = await dataAccess.get('locations');
      setLocations(locations);
    })();
  }, []);

  // const ListLocatiesGen = ({ name, id, variable }: data) => {
  //   const [checkedLocation, setCheckedLocation] = useState<any[]>([]);
  //   const handleChange = (e: any) => {
  //     const value = e.target.value;

  //     setCheckedLocation(checkedLocation.concat(value));
  //     console.log(checkedLocation);
  //     variable = checkedLocation;
  //   };

  //   //console.log(variable);
  //   return (

  //   );
  // };

  const handleChange = (e: any) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked == true) {
      if(location.length === undefined ||location.length === 0){
        setLocation([value]);
      }
      else{
        setLocation((location) => [...location, value]);
      }
    } else if (checked == false) {
      const index = location.indexOf(value);
      if (index > -1) {
        setLocation(location.slice(0, index).concat(location.slice(index + 1)));
      }
    }
  };
  if (PropsForm.currentStep !== 5) {
    // Prop: The current step
    return null;
  }
  // The markup for the Step 5 UI
  return (
    <>
      {/* <h1 className="c-logboek-titel">Vislocatie</h1> */}
      <div className="c-form-item">
        <label className="c-form-label" htmlFor="locatie">
          Kies tenminste 1 locatie
        </label>
        <IonList  className="margin-left-minus-20">
        {locations.map((locatie) => (
            <IonItem key={locatie.id}>
              
              <IonLabel>{locatie.name}</IonLabel>
              <IonCheckbox slot="start" color="primary" id={locatie.id} value={locatie.id} checked={location.length > 0 && location.includes(locatie.id)} disabled={PropsForm.readonly} onIonChange={e => handleChange(e)} />
              <div>
{locatie.id === '8de81202-c837-4181-9537-44fb853c8c42' ?  <IonIcon md={helpCircle} className="coastbusters-help-icon" onClick={e => showCoastBustersImg(e)}></IonIcon> : ""}

</div>

          </IonItem>
          
          
          ))}
        </IonList>
        <IonModal isOpen={showModal} className ='my-custom-class'>
        <label className="c-form-label" >Coastbusters locaties</label>
        <img src={coastbustersimg} alt="Coastbusters locaties" />
        <IonButton onClick={() => setShowModal(false)}>Sluiten</IonButton>
      </IonModal>
      </div>
    </>
  );
};
const Stap6: React.FC<PropsForm> = (PropsForm) => {
  const history = useHistory();
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [present] = useIonAlert();
  const [selected, setSelected] = useState<string>('');
  const [vangstToevoegenScreen, setVangstToevoegenScreen] = useState<boolean>(false);
  const [vissen, setVissen] = useState<vis[]>([]);
  const [vis, setVis] = useState<string>('');
  const [visFull, setVisFull] = useState<any>({});
  const [countHouden, setCountHouden] = useState<number>();
  const [countTeruggooi, setCountTeruggooi] = useState<number>();
  const [dataVisVangst, setDataVisVangst] = useState<any[]>([]);
  const [kg, setKg] = useState<number>(0);
  const [cmHouden, setCmHouden] = useState<any[]>([]);
  const [cmTeruggooi, setCmTeruggooi] = useState<any[]>([]);
  const [houden, setHouden] = useState<number>();
  const [ondermaats, setOndermaats] = useState<number>();
  const [bovenmaats, setBovenmaats] = useState<number>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  let rows: any = [];

  var [initialLoad, setInitialLoad] = useState<boolean>(true);
  const loadInitial = (): void => {
    Storage.get({ key: `code` }).then((value: any) => {
      if(value){
        Storage.get({ key: `id` }).then((id: any) => {
          Storage.get({ key: `${value.value}_${id.value}` }).then((result: any) => {
            let data = JSON.parse(result.value);
            if(data != null && data.visvangst){
              setDataVisVangst(data.visvangst);
            }
            Storage.get({ key: `admin` }).then((isAdm: any) => {
              setIsAdmin(isAdm.value === "true");

              setInitialLoad(false);
            });
          });
        });
      }
    });
}

  // useEffect(() => {
  //   PropsForm.show(vangstToevoegenScreen.toString());
  //   const vissenSelect = document.querySelector('ion-select');
  //       if (vissenSelect) {
  //         vissenSelect.open();
  //     }
  // }, [vangstToevoegenScreen]);
  useEffect(() => {
    (async function () {
      if(initialLoad){
        loadInitial();
      }
      else{
        const code = await Storage.get({ key: `code` });
        const id = await Storage.get({ key: `id` });
        const ret = await Storage.get({ key: `${code.value}_${id.value}`});
        if (ret.value != 'null') {
          let data = {};
          // @ts-ignore
          data = JSON.parse(ret.value);

          // @ts-ignore
          data['visvangst'] = dataVisVangst;
          await Storage.set({
            key: `${code.value}_${id.value}`,
            value: JSON.stringify(data),
          });
        }
      }
    })();
  }, [dataVisVangst]);
  useEffect(() => {
    (async function () {
      if(initialLoad){
        loadInitial();
        const data = await dataAccess.get('species');
        setVisFull({});
      setVissen(data);
      data.forEach((element: any) => {
        if (element.id == vis) {
          setVisFull(element);
        }
      });
      }
      else{
        const data = await dataAccess.get('species');
        setVisFull({});
      setVissen(data);
      data.forEach((element: any) => {
        if (element.id == vis) {
          setVisFull(element);
        }
      });

      if (dataVisVangst != null && dataVisVangst.length > 0) {
        // console.log(dataVisVangst);
        var vangst = dataVisVangst.find(x => x.id === vis);
        if(vangst){
          vangst.bovenmaats ? setBovenmaats(vangst.bovenmaats) : setBovenmaats(0);
          vangst.ondermaats ? setOndermaats(vangst.ondermaats) : setOndermaats(0);
          vangst.houden ? setHouden(vangst.houden) : setHouden(0);
          vangst.kg ? setKg(vangst.kg) : setKg(0);
          vangst.lengtesHouden ? setCountHouden(vangst.lengtesHouden.length) : setCountHouden(0);
          vangst.lengtesTeruggooi ? setCountTeruggooi(vangst.lengtesTeruggooi.length) : setCountTeruggooi(0);
          vangst.lengtesHouden ? setCmHouden(vangst.lengtesHouden) : setCmHouden([]);
          vangst.lengtesTeruggooi ? setCmTeruggooi(vangst.lengtesTeruggooi) : setCmTeruggooi([]);
        }else
        {
          setBovenmaats(0);
          setOndermaats(0);
          setHouden(0);
          setKg(0);
          setCountHouden(0);
          setCountTeruggooi(0);
          setCmHouden([]);
          setCmTeruggooi([]);
        }
        
      }

      
      }
      
    })();
  }, [vis, dataVisVangst])
  // const close = (id: number) => {
  //   rows.splice(id, 1);
  //   setCount(count - 1);
  //   NieuweVisToevoegen();
  // };

  // const processKgInput = (val: string) =>{
  //   if(!val){
  //     setKg(0);
  //   }
  //   else{
  //     setKg(parseFloat(val));
  //   }
  // }

  const Delete = (id: string) => {
    let newList = [];
    if(dataVisVangst.length !== 0 && dataVisVangst.length !== undefined){
      newList = dataVisVangst.filter(x => x.id !== id);
    }
    setDataVisVangst(newList);

    setShowPopover({ showPopover: false, event: undefined });
  };

  const vangstToevoegen = (visId: React.SetStateAction<string> | undefined | null) => {

    setShowPopover({ showPopover: false, event: undefined });

    if(visId){

      setVis(visId);
    }
    else{
      setVis('');
      if(vis === ''){
      //   const vissenSelect = document.querySelector('ion-select');
      //   if (vissenSelect) {
      //     vissenSelect.open();
      // }
      }
    }

    setVangstToevoegenScreen(true);

    setShowPopover({ showPopover: false, event: undefined });

    //history.push('/VangstToevoegen');
  };

  const terug = () => {
    setVangstToevoegenScreen(false);
  };
  const toevoegen = () => {
    let closeScreen = true;
    let newList = [];
    if(dataVisVangst.length !== 0 && dataVisVangst.length !== undefined){
      newList = dataVisVangst.filter(x => x.id !== vis);

    }

    if (visFull['measure'] == 'hoeveelheid+gewicht') {
      const data = { id: vis, vis: visFull.name, houden: houden, kg: kg, ondermaats: ondermaats, bovenmaats: bovenmaats };
      newList = newList.concat(data);
      // console.log(data);
      setDataVisVangst(newList);
      setHouden(0);
      setKg(0);
      setOndermaats(0);
      setBovenmaats(0);
    } else if (visFull['measure'] == 'gewicht') {
      const data: any = { id: vis, vis: visFull.name, kg: kg };
      newList = newList.concat(data);
      setDataVisVangst(newList);
      setKg(0);
    } else if (visFull['measure'] == 'lengtes') {
      const data: any = { id: vis, vis: visFull.name, lengtesHouden: cmHouden.slice(0,countHouden), lengtesTeruggooi: cmTeruggooi.slice(0,countTeruggooi) };
      newList = newList.concat(data);
      setDataVisVangst(newList);
      setCmHouden([]);
      setCmTeruggooi([]);
    }
    else
    {
      closeScreen = false;

      present({
        cssClass: 'alert-css',
        header: 'Opgelet',
        message: 'Gelieve een vissoort te kiezen',
        buttons: [
          'ok'
        ]
      });

    }

    if(closeScreen){
      setVangstToevoegenScreen(false);
    }
  };
  const NieuweVisToevoegen = () => {
    const addToListHouden = (e: any, i: number) => {
      var newList = cmHouden.slice(0, countHouden);
      var val = parseInt(e.target.value);
      if(val < 0 || Number.isNaN(val)){
        val = 0;
      }
      if(val > 200){
        val = 200;
      }
      if(Number.isInteger(val)){
        newList[i] = val;
      }
      newList = newList.filter(Number) 
      //const newList = cmHouden.slice(0, i).concat(parseInt(e.target.value));
      //const newList = [...cmHouden.slice(0, i), ...cmHouden.slice(i + 1)];
      //const newList = cmHouden.splice(i, 0, e.target.value);
      setCmHouden(newList);
    };
    const addToListTeruggooi = (e: any, i: number) => {
       var newList = cmTeruggooi.slice(0, countTeruggooi);
      var val = parseInt(e.target.value);
      if(val < 0 || Number.isNaN(val)){
        val = 0;
      }
      if(val > 200){
        val = 200;
      }
      if(Number.isInteger(val)){
        newList[i] = val;
      }

      newList = newList.filter(Number) 
      
      //const newList = cmTeruggooi.slice(0, i).concat(parseInt(e.target.value));
      //const newList = [...cmTeruggooi.slice(0, i), ...cmTeruggooi.slice(i + 1)];
      //const newList = cmTeruggooi.splice(i, 0, e.target.value);
      setCmTeruggooi(newList);
    };

    if (visFull['measure'] == 'gewicht') {
      rows.push(
        <div key={'gewicht'} className="c-logboek-div-visvangst-full">
          <div className="c-logboek-div-visvangst">
            <div className="c-logboek-div-visvangst-cm">
              Totaal aantal kg <input className="c-form-input input-right input-small" type='number' id="kg" name="kg" min={0} max={300} step="0.001" lang="nl-BE" value={kg  || ''} disabled={PropsForm.readonly} onChange={(e) => {
                var val = parseFloat(e.target.value);
                if(val < 0 || Number.isNaN(val)){
                  val = 0;
                }
                if(val > 300){
                  val = 300
                }
                setKg(val);
              }} />
              {/* <IonInput type="number" value={kg} id="kg" name="kg" min="0" step="0.001" onIonChange={e => setKg(e.detail.value)}></IonInput> */}

            </div>
          </div>
        </div>
      );
    } else if (visFull['measure'] == 'lengtes') {
      const RenderHouden = () => {
        let HoudenInputs: any = [];
        for (let i = 0; i < countHouden; i++) {
          HoudenInputs.push(
            <div key={`houden ${i}`} className="c-logboek-div-visvangst-cm">
              <div className="c-logboek-div-visvangst-label">Lengte vis {i + 1} (cm)</div>
              <input className="c-form-input input-right input-small" id={i.toString()} name={cmHouden[i] || ''} type="number" min="0" max="150" value={cmHouden[i] || ''} disabled={PropsForm.readonly} onChange={(e) => addToListHouden(e, i)} />
            </div>
          );
        }
        return HoudenInputs;
      };
      const RenderTeruggooi = () => {
        let TeruggooiInputs: any = [];
        for (let i = 0; i < countTeruggooi; i++) {
          TeruggooiInputs.push(
            <div key={`teruggooi ${i}`} className="c-logboek-div-visvangst-cm">
              <div className="c-logboek-div-visvangst-label">Lengte vis {i + 1} (cm)</div>
              <input className="c-form-input input-right input-small" id={i.toString()} name={cmTeruggooi[i] || ''} type="number" min="0" max="150" value={cmTeruggooi[i] || ''} disabled={PropsForm.readonly} onChange={(e) => addToListTeruggooi(e, i)} />
            </div>
          );
        }
        return TeruggooiInputs;
      };
      rows.push(
        <div key={'lengtes'} className="c-logboek-div-visvangst-full">
          <div className="c-logboek-div-visvangst">
            <h5 className="c-logboek-titel-h5">Houden</h5>
            <div className="c-logboek-div-visvangst-cm">
            <div className="c-logboek-div-visvangst-label">Aantal houden</div>
              <input className="c-form-input input-right input-small" name="houden" type="number" min="0" max="1000" value={countHouden || ''} disabled={PropsForm.readonly} onChange={(e) => 
              {
                var val = parseFloat(e.target.value);
                if(val < 0 || Number.isNaN(val)){
                  val = 0;
                }
                if(val > 1000){
                  val = 1000
                }
                setCountHouden(val);
              }} />
            </div>
            <div className="c-logboek-div-visvangst-cm-div">{RenderHouden()}</div>

            <br></br>
            <h5 className="c-logboek-titel-h5">Teruggooi</h5>
            <div className="c-logboek-div-visvangst-cm">
              <div className="c-logboek-div-visvangst-label">Aantal teruggegooid</div>
              <input className="c-form-input input-right input-small" name="teruggooi" type="number" min="0" max="1000" value={countTeruggooi || ''} disabled={PropsForm.readonly} onChange={(e) => {
                var val = parseFloat(e.target.value);
                if(val < 0 || Number.isNaN(val)){
                  val = 0;
                }
                if(val > 1000){
                  val = 1000
                }
                setCountTeruggooi(val);
              }} />
            </div>
            <div className="c-logboek-div-visvangst-cm-div">{RenderTeruggooi()}</div>
          </div>
        </div>
        // <div className="c-logboek-div-visvangst-full">
        //   <button className="c-logboek-div-visvangst-full-close" onClick={() => close(i)}>
        //     <IonIcon md={closeOutline} className="c-vangst-icon"></IonIcon>
        //   </button>
        //   <div className="c-logboek-div-visvangst">
        //     <div className="c-logboek-div-visvangst-radio">
        //       <div className="c-logboek-div-visvangst-radio-item">
        //         <input type="radio" id={'houden' + i} name={'vis' + i} value={'houden' + i} checked />
        //         <label className="c-form-radio-label" htmlFor="houden">
        //           Houden
        //         </label>
        //       </div>
        //       <div className="c-logboek-div-visvangst-radio-item">
        //         <input type="radio" id={'teruggooi' + i} name={'vis' + i} value={'teruggooi' + i} />
        //         <label className="c-form-radio-label" htmlFor="teruggooi">
        //           Teruggooi
        //         </label>
        //       </div>
        //     </div>
        //     <div className="c-logboek-div-visvangst-cm">
        //       <input className="c-form-input-duur" id={i.toString()} name={cm[i]} type="number" value={cm[i]} onChange={(e) => addToList(e, i)} /> cm
        //     </div>
        //   </div>
        // </div>
      );
      // rows.push(
      //   <button className="c-button-full c-button-addvis" onClick={() => setCount(count + 1)}>
      //     <IonIcon md={addOutline} className="c-vangst-icon"></IonIcon>
      //   </button>
      // );
    } else if(visFull['measure'] == 'hoeveelheid+gewicht') {
      rows.push(
        <div key={'maten'} className="c-logboek-div-visvangst-full">
          <div className="c-logboek-div-visvangst">
            <h5 className="c-logboek-titel-h5">Houden</h5>
            <div className="c-logboek-div-visvangst-cm">
            <div className="c-logboek-div-visvangst-label">Aantal houden</div>
              <input className="c-form-input input-right input-small" id="houden" name="houden" type="number" min="0" max="1000" value={houden || ''} disabled={PropsForm.readonly} onChange={(e) => {
                var val = parseFloat(e.target.value);
                if(val < 0 || Number.isNaN(val)){
                  val = 0;
                }
                if(val > 1000){
                  val = 1000
                }
                setHouden(val);
              }} />
            </div>
            <div className="c-logboek-div-visvangst-cm">
               
              <div className="c-logboek-div-visvangst-label">Totaal aantal kg</div>
              <input className="c-form-input input-right input-small" id="kg" name="kg" step={0.1} type="number" min="0" max="300" value={kg  || ''} disabled={PropsForm.readonly} onChange={(e) => {
                var val = parseFloat(e.target.value);
                if(val < 0 || Number.isNaN(val)){
                  val = 0;
                }
                if(val > 300){
                  val = 300
                }
                setKg(val);
              }} />
            </div>
            <br></br>
            <h5 className="c-logboek-titel-h5">Teruggooi</h5>
            <div className="c-logboek-div-visvangst-cm">
              
              <div className="c-logboek-div-visvangst-label">Aantal ondermaats</div>
              <input className="c-form-input input-right input-small" id="ondermaats" name="ondermaats" type="number" min="0" max="1000" value={ondermaats  || ''} disabled={PropsForm.readonly} onChange={(e) => {
                var val = parseFloat(e.target.value);
                if(val < 0 || Number.isNaN(val)){
                  val = 0;
                }
                if(val > 1000){
                  val = 1000
                }
                setOndermaats(val);
              }} />
            </div>
            <div className="c-logboek-div-visvangst-cm">
              <div className="c-logboek-div-visvangst-label">Aantal bovenmaats</div>
              <input className="c-form-input input-right input-small" id="bovenmaats" name="bovenmaats" type="number" min="0" max="1000" value={bovenmaats  || ''} disabled={PropsForm.readonly} onChange={(e) => {
                var val = parseFloat(e.target.value);
                if(val < 0 || Number.isNaN(val)){
                  val = 0;
                }
                if(val > 1000){
                  val = 1000
                }
                setBovenmaats(val);
              }} />
            </div>
          </div>
        </div>
      );
    }
    return rows;
  };
  // const listVisGen = () => {
  //   return (
  //     <>
  //       {vissen.map((message) => (
  //         <option key={message.id} className="c-form-select-option" value={message.id}>
  //           {message.name}
  //         </option>
  //       ))}
  //     </>
  //   );
  // };


  const vissoortHeaderModalOptions = {
    header: 'Kies een vissoort',
    //subHeader: 'Select your favorite topping',
    message: '(scrollen mogelijk)',
    translucent: true
  };

  if (PropsForm.currentStep !== 6) {
    // Prop: The current step
    return null;
  }
  // The markup for the Step 6 UI
  if (vangstToevoegenScreen == false) {
    // @ts-ignore
    document.getElementById('wizard-buttons').style.display ='flex'; 

    var geregistreerdeVissen: string | any[] = [];
    if(dataVisVangst.length !== 0 && dataVisVangst.length !== undefined){
      try{
        geregistreerdeVissen = dataVisVangst.map(a => a.id);
      }
      catch(e){
        
      }
    }

    let andereVissen = vissen.filter(v => !geregistreerdeVissen.includes(v.id))
    return (
      <>
      <IonPopover className ="c-pop-over" event={popoverState.event} isOpen={popoverState.showPopover} onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}>
        {
          PropsForm.readonly ?
<div>
            <button className="c-pop-over-item" onClick={() => vangstToevoegen(selected)}>
                      Bekijk
                    </button>
          </div>
          :
          <div>
          <button className="c-pop-over-item" onClick={() => vangstToevoegen(selected)}>
                                Bewerk
                              </button>
                              <button className="c-pop-over-item" onClick={() => Delete(selected)}>
                                Verwijder
                              </button>
                    </div>
        }
                    
                  </IonPopover>
      <div className='c-form-item'>
      <label className="c-form-label margin-bottom-10" htmlFor="locatie">
          Vangst
        </label>
        {
    PropsForm.readonly ? ""
    :
    <div style={{'width' : '100%'}}>

        <IonButton >
          <IonSelect  placeholder='Kies een vissoort' style={{'opacity' : '0'}} interfaceOptions={vissoortHeaderModalOptions}  onIonChange={e => vangstToevoegen(e.detail.value)} className='full-width padding-left-0 div-left'>
                          {
                            andereVissen.map((message) => (
                                <IonSelectOption key={message.id} value={message.id}>{message.name}</IonSelectOption>
                              ))
                          }
          </IonSelect><div id='vangst-toevoegen-content'>Vissoort toevoegen</div>
        </IonButton>  
          

      </div>

  }
        { !dataVisVangst ? "" :
        <div className="c-logboek-gevangenvis">
          {

          dataVisVangst && dataVisVangst.length > 0 ?
          
          dataVisVangst.map((message: any) => (
            
            <div key={message.id} className="c-vangsten-item" >
              <div className="c-vangsten-item-naam">{message.vis}
              <div className='c-vangsten-item-options' vis-id={message.id}
                    onClick={(e: any) => {
                      e.persist();
                      setSelected(message.id);
                      setShowPopover({ showPopover: true, event: e });
                    }} >
                      {
                        PropsForm.readonly ? "" : <IonIcon md={ellipsisVertical}></IonIcon>
                      }
              </div>
              {
                //message.houden != null && message.houden != undefined && message.lengtesHouden.length === 0 && message.lengtesHouden.length === 0 
                vissen.filter(x => x.name === message.vis)[0].measure == "hoeveelheid+gewicht" ? 
                <div>
                  <div className='c-vangsten-item-details'>
                    <div className='c-vangsten-item-details-column'>
                      # Houden: {message.houden}
                    </div>
                    <div className='c-vangsten-item-details-column'>
                      Gewicht: {message.kg}kg
                    </div>
                  </div> 
                  <div className='c-vangsten-item-details'>
                    <div className='c-vangsten-item-details-column'>
                      # Teruggooi bovenmaats: {message.bovenmaats}
                    </div>
                    <div className='c-vangsten-item-details-column'>
                      # Teruggooi ondermaats: {message.ondermaats}
                    </div>
                  </div>
                </div>
                
                : vissen.filter(x => x.name === message.vis)[0].measure == "lengtes" ? 
                <div>
                  <div className='c-vangsten-item-details'>
                    Lengtes houden: {message.lengtesHouden.join(", ")}
                  </div>
                  <div className='c-vangsten-item-details'>
                    Lengtes teruggooi: {message.lengtesTeruggooi.join(", ")}
                  </div>
                </div> 
                : 
                <div className='c-vangsten-item-details'>
                  Gewicht: {message.kg}kg
                </div>
              }
              
</div>
            </div>
          )
          ) : <div className='logboek-row div-left'>Nog geen vangsten geregistreerd</div>}
        </div>
        }



      </div>
        

      </>
    );
  } else if (vangstToevoegenScreen == true) {

    // HACKJOB
    const popovers = document.getElementsByClassName("c-pop-over");
    for (var i = 0; i < popovers.length; i++) {
      popovers[i].setAttribute('is-open', 'false');
   }

    document.getElementById('wizard-buttons').style.display ='none'; 
    // let geregistreerdeVissen = dataVisVangst.map(a => a.id);
    // let andereVissen = vissen.filter(v => !geregistreerdeVissen.includes(v.id))
    // let alGeregistreerd = geregistreerdeVissen.includes(vis);
    var bestaandeVis = vissen.find(v => v.id === vis);
    return (
      <div className="container container-logboek container-loggedIn">
        <div className="c-form-div-logboek">
          
        <div className='c-form-item'>
        <label className="c-form-label">
          Vangst toevoegen: {bestaandeVis?.name}
        </label>
        {NieuweVisToevoegen()}

        </div>
        
            {/* <select className="c-form-select" name="vissen" id="vissen" onChange={(e) => setVis(e.target.value)} value={vis}>
              {listVisGen()}
            </select> */}
            {/* {!alGeregistreerd ?
            <div>
<IonItem>
  <IonLabel>Kies een vissoort</IonLabel>
  <IonSelect title="hello" onIonChange={e => setVis(e.detail.value)}>
                {
                  andereVissen.map((message) => (
                      <IonSelectOption key={message.id} value={message.id}>{message.name}</IonSelectOption>
                    ))
                }
                </IonSelect>
</IonItem>
                
            </div>

                :
                <div>{bestaandeVis?.name}</div>
            } */}


            

          <div className="c-form-buttons-div">
          <IonButton color="primary" onClick={() => terug()}>Vorige</IonButton>
          <div className="c-form-button-whitespace"></div>
<IonButton color="primary" disabled={PropsForm.readonly} onClick={() => toevoegen()}>Toevoegen</IonButton>
        </div>
        </div>
      </div>
    );
  }
  return <></>;
};
// const Stap7: React.FC<PropsForm> = (PropsForm) => {
//   const history = useHistory();

//   if (PropsForm.currentStep !== 7) {
//     // Prop: The current step
//     return null;
//   }



//   // The markup for the Step 7 UI
//   return (
//     <>
//       {/* <h1 className="c-logboek-titel">Info</h1> */}
     
//           <div id="logboek-confirm-message" className="c-form-item c-form-item-logboek">
//             <label className="c-form-label margin-bottom-10">
//               Vangst versturen
//             </label>
//             {
//               PropsForm.readonly ? <p className='logboek-row div-left'>Dit logboek werd eerder al verstuurd. Deze opnieuw versturen is niet meer mogelijk.</p>
//               :
//               <p className='logboek-row div-left'>Bedankt voor de registratie. Indien de gegevens volledig zijn kunt u deze nu doorsturen.</p>
//             }


//           </div>
//           <div id="logboek-received-message" className="c-form-item c-form-item-logboek hidden">
//             <label className="c-form-label margin-bottom-10">
//             Logboek ontvangen
//             </label>
//             <p className='logboek-row div-left'>Bedankt, we hebben uw logboek ontvangen!</p>
            

//           </div>

//     </>
//   );
//};
export default Logboek;