import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import React, { useState, useEffect } from 'react';
const Logout: React.FC = () => {
  const history = useHistory();
  const { Storage } = Plugins;

  useEffect(() => {
    const location = {
      pathname: '/login',
      state: { fromDashboard: true },
    };
    const removeLocalstorage = async () => {
      await Storage.remove({ key: 'token' });
      await Storage.remove({ key: 'admin' });
      await Storage.remove({ key: 'code' });
      await Storage.remove({ key: 'token_valid_to' });
    };
    removeLocalstorage().then(response => {
      history.push(location);
    });
    
  }, []);
  return <></>;
};

export default Logout;
