import { useHistory } from 'react-router-dom';
import { Storage } from '@capacitor/storage';
import { IonPopover, IonButton, IonAlert, useIonAlert } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import API_URL from '../utils/api';

interface data {
  date: string;
  time: string;
  location: string;
}

const Home: React.FC = () => {
  //const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [code, setCode] = useState<string>('');
  const [isNew, setIsNew] = useState<boolean>(true);
  const [date, setDate] = useState<Date>(new Date());
  const [overview, setOverview] = useState<data[]>([]);
  const history = useHistory();


  useEffect(() => {
    (async function () {
      checkAuthentication();
      Storage.get({ key: `code` }).then((value: any) => {
        if(value){
          Storage.get({ key: `${value.value}_` })
          .then((result: any) => {
            let data = JSON.parse(result.value);
            if (!data || !data.duur || data.duur === 0) { // als de gebruiker al een duur heeft ingevuld (1ste stap)
              setIsNew(true);
              setDate(null);
            } else {
              setIsNew(false);
              setDate(data.datum);
            }

            Storage.get({ key: `admin` }).then((admvalue: any) => {
              let tmpAdm = JSON.parse(admvalue.value);
              if(!tmpAdm){
                setCode(value.value);
              }
            });
          });

          
        }})

    })();
  }, []);


  const checkAuthentication = async () => {
    const tokenValidTo = await Storage.get({ key: `token_valid_to` });
    if (tokenValidTo.value && parseInt(tokenValidTo.value) > new Date().getTime()) {
      //ok
    }
  else{
    const location = {
      pathname: '/login',
      state: { fromDashboard: true },
    };
    const removeLocalstorage = async () => {
      await Storage.remove({ key: 'token' });
      await Storage.remove({ key: 'admin' });
      await Storage.remove({ key: 'code' });
      await Storage.remove({ key: 'token_valid_to' });
    };
    removeLocalstorage().then(response => {
      history.push(location);
    });
  }
  };

  const OpenLogboek = async () => {
    await Storage.set({
      key: `id`,
      value: '',
    });

    if(!isNew){
      history.push('/Logboek');

    }
    else{
      await Storage.remove({ key: code + "_" });
      let locaties: { array: any[] } = { array: [] }
      let vv: { array: any[] } = { array: [] }
      const data = { code: code, datum: '', duur: 0, aas: '', land: '72dad0d1-a614-4351-b90e-966c83c264ab', locaties: locaties, techniek: '', visvangst: vv };
      await Storage.set({
        key: `${code}_`,
        value: JSON.stringify(data),
      });
      
      history.push('/Logboek');
    }

  };

  const SluitLogboek = async () => {

    Storage.get({ key: `code` }).then(async (value: any) => {
      if(value){
        await Storage.set({
          key: `${value.value}_`,
          value: JSON.stringify({ land: '72dad0d1-a614-4351-b90e-966c83c264ab'}),
        });
  
        setIsNew(true);
      }
    }); 
  };

  const [presentAlert] = useIonAlert();
  return (
    <div className="container container-logboek container-loggedIn">
      <div className="c-home-nieuwLogboek-div">
<div className='align-left'>
{/* <h1 className="c-home-titel">Doorsturen vangsten</h1> */}

        {isNew ?  <div><div className="page-title">Welkom</div><p className="c-home-p">Registreer nu uw vangst van de voorbije trip.
Vroegere vangsten kan u steeds nakijken via het
menu-item logboeken.</p></div> :
                <div><div className="page-title">Openstaand logboek</div><p className="c-home-p">We stellen vast dat er nog een niet verstuurd
                logboek met visactiviteit op {new Date(date).getDate()}-{new Date(date).getMonth()+1}-{new Date(date).getFullYear()} aanwezig is. Gelieve dit logboek eerst af te werken en te versturen of te verwijderen.</p></div>
              }
        {/* <button className="c-button-full c-button-home" onClick={() => NieuwLogboekMaken()}>
          Nieuw logboek
        </button> */}
</div>
        
        {
          !isNew ? <div>
              <IonButton style={{ 'float': 'right'}} onClick={() => OpenLogboek()}><span>Bewerk logboek</span></IonButton>
              <IonButton color='danger' style={{ 'float': 'left'}} onClick={() =>
        presentAlert({
          header: 'Bent u zeker dat u het openstaande logboek wilt verwijderen?',
          cssClass: 'custom-alert',
          buttons: [
            {
              text: 'Nee',
              cssClass: 'alert-button-cancel',
            },
            {
              text: 'Ja',
              cssClass: 'alert-button-confirm',
              handler: () => {
                SluitLogboek();
              },
            },
          ],
        })
      }>{ <span>Verwijder</span>}</IonButton>
            </div> : <div>
              <IonButton onClick={() => OpenLogboek()}><span>Registreer vangst</span></IonButton>
            </div>
        }
      </div>
    </div>
  );
};

export default Home;
