import { useHistory } from 'react-router-dom';
import { Storage } from '@capacitor/storage';
import { IonPopover, IonButton, IonSpinner, IonFab, IonFabButton, IonIcon, IonList, IonListHeader, IonLabel, IonSelect, IonSelectOption, useIonAlert, IonAccordion, IonAccordionGroup, IonItem} from '@ionic/react';
import { ellipsisVertical } from 'ionicons/icons';
import dataAccess from '../utils/fetch';

import React, { useState, useEffect } from 'react';
import API_URL from '../utils/api';

interface data {
  id: string;
  code: string
  datumString: string;
  duurString: string;
  locatieString: string;
  visvangst: any[];
}

interface vis {
  id: string;
  measure: string
  name: string;
  place: string;
}

const OverzichtScreen: React.FC = () => {
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [overview, setOverview] = useState<data[]>([]);
  const [token, setToken] = useState<string>('');
  const [emptyMessage, setEmptyMessage] = useState<string>('Logboeken ophalen...');
  const [spinner, setSpinner] = useState<string>('c-spinner-hide');
  const [selected, setSelected] = useState<string>('');
  const [years, setYears] = useState<number[]>([]);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [vissen, setVissen] = useState<vis[]>([]);
  const history = useHistory();
  var [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [presentAlert] = useIonAlert();
  const NieuwLogboekMaken = () => {
    history.push('/Logboek');
  };
  const getData = async () => {
    setSpinner('c-spinner');
    const { value } = await Storage.get({ key: 'token' });
    // @ts-ignore
    setToken(value);
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + value);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    fetch(`${API_URL}/overview?year=${year}`, requestOptions)
      .then((r) => r.json())
      .then((data) => {
        setSpinner('c-spinner-hide');
        setOverview(data);
        if(data.length === 0){
          setEmptyMessage('Geen logboeken');
        }
      });

  };
  useEffect(() => {
    (async function () {
      Storage.get({ key: `admin` }).then((admvalue: any) => {
        let isAdmin = JSON.parse(admvalue.value);
        setIsAdmin(isAdmin === true);
      });
  
      const data = await dataAccess.get('species');
      setVissen(data);
  
      var y = [];
      for (let index = new Date().getFullYear(); index >= 2017; index--) {
        y.push(index);
      }
      setYears(y);
      getData();
      
    })();
    
    
  }, [year]);
  const getItem = async () => {
    const { value } = await Storage.get({ key: 'code' });
    const location = {
      pathname: '/login',
      state: { fromDashboard: true },
    };
    if (value == null) {
      history.replace(location);
    }
  };
  getItem();
  const Delete = (id: string) => {
    setShowPopover({ showPopover: false, event: undefined });

    presentAlert({
      header: 'Bent u zeker dat u het geselecteerde logboek wilt verwijderen?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Nee',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Ja',
          cssClass: 'alert-button-confirm',
          handler: () => {
            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + token);

            var requestOptions = {
              method: 'DELETE',
              headers: myHeaders,
            };


            fetch(`${API_URL}/logboek?id=${id}`, requestOptions)
            .then((response) => {
              if(response.ok){
                setShowPopover({ showPopover: false, event: undefined })
                getData();
              }
              else
              {
                response.text()
                .then((result) => {
                  presentAlert({
                    cssClass: 'alert-css',
                    header: 'Fout',
                    message: result,
                    buttons: [
                      'ok'
                    ]
                  });
                  
                })
              }
            } 
            )
    }}],
    })
  };

  const CatchDetails = async (id: string) => {
    setShowPopover({ showPopover: false, event: undefined });
    // var myHeaders = new Headers();
    // myHeaders.append('Authorization', 'Bearer ' + token);

    // var requestOptions = {
    //   method: 'GET',
    //   headers: myHeaders,
    // };

    // const code = await Storage.get({ key: `code` });
    // //const ret = await Storage.get({ key: `${code.value}`});

    // fetch(`${API_URL}/logboek?id=${id}`, requestOptions)
    //   .then(async response => {
        
    //     var logboek = await response.text();

    //     await Storage.set({
    //       key: `id`,
    //       value: `${id}`,
    //     });
          
    //     await Storage.set({
    //       key: `${code.value}_${id}`,
    //       value: logboek,
    //     });
    //     history.push(`/Logboek/${id}`)
    //   })
    //   .catch((error) => console.log('error', error));
  };

  const compareWith = (e1: number, e2: number) => {
    
    return e1 && e2 ? e1 === e2 || e1 == e2 : false;
  }

  const IsLastTwoWeeks = (dateString: string) => {
    
    var dateArray = dateString.split("/");
    var dateLogboek = new Date(dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0]);
    var dateNow = new Date();

    const diffTime = Math.abs(dateNow.getTime() - dateLogboek.getTime());
    const diffDays = diffTime / (1000 * 60 * 60 * 24); 

    return diffDays < 15;
    //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  }

  const Edit = async (id: string) => {
    setShowPopover({ showPopover: false, event: undefined });
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    const code = await Storage.get({ key: `code` });
    //const ret = await Storage.get({ key: `${code.value}`});

    fetch(`${API_URL}/logboek?id=${id}`, requestOptions)
      .then(async response => {
        
        var logboek = await response.text();

        await Storage.set({
          key: `id`,
          value: `${id}`,
        });

        await Storage.set({
          key: `userCode`,
          value: `${overview.filter(x => x.id === selected)[0].code}`,
        });
          
        await Storage.set({
          key: `${code.value}_${id}`,
          value: logboek,
        });
        history.push(`/Logboek/${id}`)
      })
      .catch((error) => console.log('error', error));
  };

    return (
    
      <div className="container container-loggedIn">
        <IonPopover className="c-pop-over" event={popoverState.event} isOpen={popoverState.showPopover} onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}>
          {
            isAdmin || (selected != null && selected != undefined && overview && overview.length > 0 && overview.filter(x => x.id === selected).length === 1 && IsLastTwoWeeks(overview.filter(x => x.id === selected)[0].datumString)) ?
            <div>
              <button className="c-pop-over-item" onClick={() => Edit(selected)} >
                      Bewerk
                    </button>
                    <button className="c-pop-over-item" onClick={() => Delete(selected)}>
                      Verwijder
                    </button>
            </div>
            
                    :
                    <div>
                    <button className="c-pop-over-item" onClick={() => Edit(selected)} >
                      Bekijk
                    </button>
                    </div>
                   
          }
                    
                  </IonPopover>
        {/* <h1 className="c-overzicht-titel">Logboeken</h1> */}
        
          <div className="c-form-item c-form-item-logboek">
            <IonSelect value={year} compareWith={compareWith} className='full-width padding-left-0 div-left ' placeholder="Selecteer een jaar" onIonChange={e => setYear(e.detail.value)}>
                {years.map((message) => (
                    <IonSelectOption key={message} value={message}>{message}</IonSelectOption>
                  ))}
            </IonSelect>
            <div className="page-title">Overzicht logboeken</div>
            <div className="c-logboek-items">
              {
                overview.length <= 0 ? <p>{emptyMessage}</p>              
                :
                <div>
                  <IonAccordionGroup multiple={true}>
                  {
                    overview.map((message) => (
                    <div key={message.id}>
                      <IonAccordion key={message.id} toggleIconSlot="start">
                      <IonItem slot="header" color="light">
                        <IonLabel>
                          <div key={message.id} className='c-logboek-overview-item'>
                            <div>
                            {
                              isAdmin ? <span>{message.datumString} &emsp; {message.code}</span> : <span>{message.datumString}</span>
                            }

                              
                            </div>
                          </div>
                        </IonLabel>
                      </IonItem>
                      <div className="ion-padding c-vangsten-details" slot="content">
                        {
                          message.visvangst.length === 0 ? "Geen vangsten" : 
                          message.visvangst.map((vv) => (
                            
                            vissen.filter(x => x.name === vv.vis)[0].measure == "hoeveelheid+gewicht" ? 
                            <div key={vv.id}>
                              <IonLabel>{vv.vis}</IonLabel>
                              <div className='c-vangsten-item-details'>
                                <div className='c-vangsten-item-details-column'>
                                  # Houden: {vv.houden}
                                </div>
                                <div className='c-vangsten-item-details-column'>
                                  Gewicht: {vv.kg}kg
                                </div>
                              </div> 
                              <div className='c-vangsten-item-details'>
                                <div className='c-vangsten-item-details-column'>
                                  # Teruggooi bovenmaats: {vv.bovenmaats}
                                </div>
                                <div className='c-vangsten-item-details-column'>
                                  # Teruggooi ondermaats: {vv.ondermaats}
                                </div>
                              </div>
                            </div>
                            
                            : vissen.filter(x => x.name === vv.vis)[0].measure == "lengtes" ? 
                            <div key={vv.id}>
                              <IonLabel>{vv.vis}</IonLabel>
                              <div className='c-vangsten-item-details'>
                                Lengtes houden: {vv.lengtesHouden.join(", ")}
                              </div>
                              <div className='c-vangsten-item-details'>
                                Lengtes teruggooi: {vv.lengtesTeruggooi.join(", ")}
                              </div>
                            </div> 
                            : 
                            <div key={vv.id}>
                              <IonLabel>{vv.vis}</IonLabel>
                              <div className='c-vangsten-item-details'>
                                Gewicht: {vv.kg}kg
                              </div>
                            </div>
                          ))}  
                        
                      </div>
                    </IonAccordion>
                      <div className='c-vangsten-item-options' logboek-id={message.id}
                                onClick={(e: any) => {
                                  e.persist();
                                  setSelected(message.id);
                                  setShowPopover({ showPopover: true, event: e });
                                }}>
                                <IonIcon md={ellipsisVertical}></IonIcon>
                              </div>
                    </div>
                    
                    
                  ))}     
                  </IonAccordionGroup>
               </div>
          
              }
            </div>
          </div>
        {/* <table className="c-table">
          <thead className="c-table-header">
            <tr>
              <th className="c-table-header-item">Plaats</th>
              <th className="c-table-header-item">Datum</th>
              <th className="c-table-header-item"></th>
            </tr>
          </thead>
          <tbody>
            {overview.map((message) => (
              <tr key={message.id}>
                <td className="c-table-body-item">{message.location}</td>
                <td className="c-table-body-item">{message.date}</td>
                <td className="c-table-body-item">
                  
                  <button
                    className="c-button-moreinfo" logboek-id={message.id}
                    onClick={(e: any) => {
                      e.persist();
                      setSelected(message.id);
                      setShowPopover({ showPopover: true, event: e });
                    }}
                  >
                    <svg className="c-pop-up-selection" viewBox="0 0 108 400">
                      <g>
                        <circle cx="54" cy="56.47" r="53.5" />
                        <path
                          d="M54,3.47c29.22,0,53,23.78,53,53s-23.78,53-53,53s-53-23.78-53-53S24.78,3.47,54,3.47 M54,2.47
      c-29.82,0-54,24.18-54,54s24.18,54,54,54s54-24.18,54-54S83.82,2.47,54,2.47L54,2.47z"
                        />
                      </g>
                      <g>
                        <circle cx="54" cy="199.73" r="53.5" />
                        <path
                          d="M54,146.73c29.22,0,53,23.78,53,53s-23.78,53-53,53s-53-23.78-53-53S24.78,146.73,54,146.73 M54,145.73
      c-29.82,0-54,24.18-54,54s24.18,54,54,54s54-24.18,54-54S83.82,145.73,54,145.73L54,145.73z"
                        />
                      </g>
                      <g>
                        <circle cx="54" cy="343" r="53.5" />
                        <path
                          d="M54,290c29.22,0,53,23.78,53,53s-23.78,53-53,53S1,372.22,1,343S24.78,290,54,290 M54,289
      c-29.82,0-54,24.18-54,54s24.18,54,54,54s54-24.18,54-54S83.82,289,54,289L54,289z"
                        />
                      </g>
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table> */}
        <div className={spinner}>
          <IonSpinner />
        </div>
        {/* <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab> */}
      </div>
    );

};

export default OverzichtScreen;
