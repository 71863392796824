import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonProgressBar, IonLabel } from '@ionic/react';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import LogboekScreen from '../components/Logboek';

interface Params {
  id: string;
}
const Logboek: React.FC = () => {
  const { id } = useParams<Params>();
  const [progressbar, setProgressbar] = useState<number>(0);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Vangstregistratie</IonTitle>
          <img slot="end" className="c-header-logo" src="assets/images/recvis.png" alt="logo recvis" />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonProgressBar value={progressbar}>
        </IonProgressBar>
        <LogboekScreen progressbar={setProgressbar} id={id} />
        <div style={{'position': 'relative'}}>
          <div id='progress-step'>Stap 1/6</div>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Logboek;
